import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FetchBarcodeListItemsAction } from '../../actions/fetch-barcode-list-items.action';
import { BarcodeState } from '../../barcode.state';
import { Observable } from 'rxjs';
import { BarcodeList, ID_Output } from '../../../../../../../../common/interfaces/prisma.binding';
import { NGXLogger } from 'ngx-logger';
import { AddBarcodeListItemMutation } from '../../mutations/add-barcode-list-item.mutation';
import { UpdateBarcodeListItemMutation } from '../../mutations/update-barcode-list-item.mutation';
import { DeleteBarcodeListItemMutation } from '../../mutations/delete-barcode-list-item.mutation';

@Component({
  selector: 'tt-barcode-items',
  templateUrl: './barcode-items.component.html',
  styleUrls: ['./barcode-items.component.scss'],
})
export class BarcodeItemsComponent {
  editField: string;

  @Select(BarcodeState.barcodeListItems) barcodeListItems$: Observable<BarcodeList[]>;
  barcodeListItems: BarcodeList[] = [];

  constructor(private store: Store,
              private logger: NGXLogger,
              private addBarcodeListItemMutation: AddBarcodeListItemMutation,
              private updateBarcodeListItemMutation: UpdateBarcodeListItemMutation,
              private deleteBarcodeListItemMutation: DeleteBarcodeListItemMutation,
  ) {}

  ngOnInit() {

    this.store.dispatch(new FetchBarcodeListItemsAction());
    this.barcodeListItems$.subscribe(barcodeListItems => {
      this.barcodeListItems = barcodeListItems;
    });
  }

  updateList(id: ID_Output, property: string, event: any) {
    const dataToUpdate: any = { id };
    let value = event.target.textContent;
    if (property === 'price') {
      if (typeof this.filterInt(value) === 'number') {
        value = this.filterInt(value);
      } else {
        value = 0;
      }
    }
    dataToUpdate[property] = value;
    this.updateBarcodeListItemMutation.mutate(dataToUpdate)
      .subscribe(({ data }) => {
        this.logger.log('got new Barcode items', data);
        // refresh store
        this.store
          .dispatch(new FetchBarcodeListItemsAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  remove(id: any) {
    this.deleteBarcodeListItemMutation.mutate({
        id,
      })
      .subscribe(({ data }) => {
        this.logger.log('remove Barcode items', data);
        // refresh store
        this.store
          .dispatch(new FetchBarcodeListItemsAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });
  }

  add() {
    this.addBarcodeListItemMutation.mutate({
        ean: '',
        data: '',
        price: 0.00,
        internalId: '0',
      })
      .subscribe(({ data }) => {
        this.logger.log('got new Barcode items', data);
        // refresh store
        this.store
          .dispatch(new FetchBarcodeListItemsAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  changeValue(id: ID_Output, property: string, event: any) {
    // this.editField = event.target.textContent;
  }

  filterInt = (value) => {
    if (/^(\-|\+)?([0-9,.]+|Infinity)$/.test(value))
      return Number(value);
    return NaN;
  };
}

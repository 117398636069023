import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FetchProjectsAction } from '../../../../admin/project-admin/actions/fetch-projects.action';
import { Observable } from 'rxjs';
import { ProjectAdminState } from '../../../../admin/project-admin/project-admin.state';
import {
  Project,
  TimeEntry,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { CreateTimeEntryAction } from '../../../../admin/project-admin/actions/create-time-entry.action';
import { FetchTimeEntriesAction } from '../../../../admin/project-admin/actions/fetch-time-entries.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteTimeEntryAction } from '../../../../admin/project-admin/actions/delete-time-entry.action';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { AuthState } from '../../../../shared/state/auth.state';
import { ToggleTimeEntryApprovalAction } from '../../../../admin/project-admin/actions/toggle-time-entry-approval.action';
import { UserState } from '../../../../admin/user/user.state';
import { FetchUserAction } from '../../../../admin/user/actions/fetch-user.action';

@Component({
  selector: 'tt-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.css'],
})
export class TimeTrackingComponent implements OnInit {
  @Select(ProjectAdminState.projects) projects$: Observable<Project[]>;
  @Select(ProjectAdminState.timeEntries) timeEntries$: Observable<TimeEntry[]>;
  @Select(AuthState.isAdmin) isAdmin$: Observable<boolean>;
  @Select(UserState.users) users$: Observable<User[]>;

  timeForm: FormGroup;
  timeEntriesDataSource: MatTableDataSource<TimeEntry>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsAdmin: string[] = [
    'date',
    'bookedTime',
    'project',
    'description',
    'user',
    'action',
  ];

  displayedColumns: string[] = [
    'date',
    'bookedTime',
    'project',
    'description',
    'action',
  ];
  accordionExpandedState = false;
  selectedUser: User;
  isAdmin: boolean;
  private _currentUser: User;

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    public readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isAdmin$.subscribe((admin) => {
      this.isAdmin = admin;
      if (this.isAdmin) {
        this.store.dispatch(new FetchUserAction());
      }
    });
    this.store.dispatch([
      new FetchTimeEntriesAction(),
      new FetchProjectsAction(),
    ]);

    this.timeEntries$.subscribe((timeEntries) => {
      this.timeEntriesDataSource = new MatTableDataSource(timeEntries);
      this.timeEntriesDataSource.paginator = this.paginator;
      this.timeEntriesDataSource.sort = this.sort;
    });
    this.timeForm = this.formBuilder.group({
      project: ['', Validators.required],
      date: ['', Validators.required],
      bookedTime: ['0:00', Validators.required],
      description: ['', Validators.required],
    });

    this._currentUser = this.store.selectSnapshot(
      AuthState.userDetails,
    ) as User;
  }

  timeChanges() {
    const bookedTime = this.timeForm.get('bookedTime').value;
    if (bookedTime.indexOf(':') === -1) {
      this.timeForm.patchValue({ bookedTime: bookedTime + ':00' });
    }
  }

  async createTimeEntry() {
    let userId = this._currentUser.id;
    if (this.selectedUser) {
      userId = this.selectedUser.id;
    }
    const timeEntry = {
      date: this.timeForm.get('date').value.toISOString(),
      bookedTime: this.createBookedTime(this.timeForm.get('bookedTime').value),
      description: this.timeForm.get('description').value,
      project: (this.timeForm.get('project').value as Project).id,
      userId: userId,
    };
    await this.store.dispatch(new CreateTimeEntryAction(timeEntry)).toPromise();
    this.timeForm.reset();
  }

  createBookedTime(bookedTime: string): number {
    const timeParts = bookedTime.split(':');
    return parseInt(timeParts[0], 10) * 3600 + parseInt(timeParts[1], 10) * 60;
  }

  deleteBookedTime(bookedTimeId: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Zeiteintrag wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store
          .dispatch(new DeleteTimeEntryAction(bookedTimeId))
          .subscribe(() => {
            this.store.dispatch(new FetchTimeEntriesAction());
          });
      }
    });
  }

  approveBookedTime(bookedTimeId: string, checked) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Zeiteintrag wirklich bestätigen / bestätigung aufheben?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store
          .dispatch(new ToggleTimeEntryApprovalAction(bookedTimeId, checked))
          .subscribe(() => {
            this.store.dispatch(new FetchTimeEntriesAction());
          });
      }
    });
  }

  onUserSelectionChange(event: User[]) {
    this.accordionExpandedState = false;
    this.selectedUser = event[0];
    console.log(event);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Observable } from 'rxjs';
import { BarcodeItem, User } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { BarcodeState } from '../../barcode.state';
import { DeleteBarcodeMutation } from '../../mutations/delete-barcode.mutation';
import { FetchBarcodesAction } from '../../actions/fetch-barcodes.action';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { UserState } from '../../../user/user.state';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'tt-barcodes-overview',
  templateUrl: './barcodes-overview.component.html',
  styleUrls: ['./barcodes-overview.component.scss'],
})
export class BarcodesOverviewComponent implements OnInit {
  displayedColumns: string[] = ['ean', 'barcodeItemType', 'userId', 'scannedDate', 'metaData', 'price', 'actions'];

  dataSource: MatTableDataSource<BarcodeItem>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(BarcodeState.barcodes) barcodes$: Observable<BarcodeItem[]>;
  @Select(UserState.users) users$: Observable<User[]>;
  public searchForm: FormGroup;

  selectedUser = '';
  fullText = '';
  startDate;
  endDate;

  constructor(private store: Store,
              public dialog: MatDialog,
              private logger: NGXLogger,
              private deleteBarcodeMutation: DeleteBarcodeMutation,
  ) {
  }

  ngOnInit() {
    this.searchFormInit();

    this.store.dispatch([new FetchUserAction(), new FetchBarcodesAction()]);
    combineLatest([this.users$, this.barcodes$]).subscribe(([users, barcodes]) => {
      barcodes.map(barcode => {
        const currentUser = users.find(user => user.id === barcode.userId);
        if (currentUser) {
          barcode.userId = `${currentUser.firstName} ${currentUser.lastName} (Username: ${currentUser.username})`;
        }
      });
      this.dataSource = new MatTableDataSource(barcodes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.getFilterPredicate();
    });

  }

  searchFormInit() {
    this.searchForm = new FormGroup({
      fullText: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(new Date()),
      user: new FormControl(''),
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  getFilterPredicate() {
    return (row: BarcodeItem, filters: string) => {
      const filterArray = filters.split('$');
      const startDate = filterArray[0];
      const endDate = filterArray[1];
      const user = filterArray[2];
      const fullText = filterArray[3];
      const matchFilter = [];

      const scannedDate = new Date(row.scannedDate.toString()).toLocaleDateString('de-DE');
      const userRow = row.userId;

      const isBetween = (date, min, max) => (date.getTime() >= min.getTime() && date.getTime() <= max.getTime());

      const userFilter = userRow.toLowerCase().includes(user.toLocaleLowerCase());
      const fullTextFilter = (scannedDate.toString().includes(fullText.toLowerCase())) ||
        (userRow.toString().toLowerCase().includes(fullText.toLowerCase())) ||
        (row.ean.toString().toLowerCase().includes(fullText.toLowerCase()));
      let dateFilter = true;
      if (startDate && endDate) {
        dateFilter = isBetween(new Date(row.scannedDate), new Date(new Date(startDate).setHours(0, 0, 0, 0)), new Date(new Date(endDate).setHours(23, 59, 59, 999)));
      }
      matchFilter.push(userFilter);
      matchFilter.push(fullTextFilter);
      matchFilter.push(dateFilter);
      return matchFilter.every(Boolean);
    };
  }

  applyFilter() {
    const startDate = this.searchForm.get('startDate').value;
    const endDate = this.searchForm.get('endDate').value;
    const userId = this.searchForm.get('user').value;
    const fullText = this.searchForm.get('fullText').value;
    console.log(userId);
    this.startDate = (startDate === null || startDate === '') ? '' : startDate.toDateString();
    this.endDate = (endDate === null || endDate === '') ? '' : endDate.toDateString();
    this.fullText = fullText === null ? '' : fullText;
    this.selectedUser = (userId === null || userId === '') ? '' : userId;

    const filterValue = this.startDate + '$' + this.endDate + '$' + this.selectedUser + '$' + fullText;
    console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  userChanged() {
    // this.applyFilter(this.selectedUser);
  }

  deleteBarcode(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Barcode wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteBarcodeMutation.mutate({ id }).subscribe(({ data }) => {
          this.logger.log('got Barcode delete data', data);
          this.store
            .dispatch(new FetchBarcodesAction());
        }, (error) => {
          error.graphQLErrors.map(({ message }, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });
  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {TeamDetailComponent} from './components/team-detail/team-detail.component';
import {TeamOverviewComponent} from './components/team-overview/team-overview.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [],
  declarations: [TeamOverviewComponent, TeamDetailComponent],
})
export class TeamModule {
}

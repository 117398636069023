import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {ElectricMeter} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allElectricMeters: ElectricMeter[];
}

@Injectable({
  providedIn: 'root',
})
export class AllContainerQuery extends Query<Response> {
  document = gql`
      query allContainer {
          containers {
              connectedWidth {
                  id
              }
              internalNumber
              description
              electricMeter {
                  id
              }
              id
              location
              team {
                  id
                  name
                  users {
                    lastName
                    username
                    firstName
                  }
              }
              user {
                  id
                  firstName
                  lastName
                  username
              }
          }
      }
  `;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../common/angular/environments/environment';
import { BillomatDtoInterface } from '../../../../../../common/interfaces/billomat-dto.interface';

@Injectable()
export class BillomatService {
  endpointString = 'billomat';

  constructor(private readonly http: HttpClient) {}

  async getUsersInvoicesByBillomatclientNumber(
    invoiceID: string,
  ): Promise<BillomatDtoInterface[]> {
    let invoices = [];
    if (invoiceID) {
      try {
        invoices = await this.http
          .get<BillomatDtoInterface[]>(
            `${environment.imageBackend}/${this.endpointString}/invoices/${invoiceID}`,
          )
          .toPromise();
      } catch (e) {}
    }
    return invoices;
  }

  getInvoiceByBillomatInvoiceId(invoiceId: number): Promise<any> {
    return this.http
      .get(
        `${environment.imageBackend}/${this.endpointString}/invoice/${invoiceId}`,
      )
      .toPromise();
  }

  getAllBillomatInvoices(invoiceIds: number[]): Promise<any> {
    return this.http
      .post(
        `${environment.imageBackend}/${this.endpointString}/invoices/`,
        invoiceIds,
      )
      .toPromise();
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Data, StripePlan } from '../../../../../../../../common/interfaces/stripe.bindings';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { StripePaymentService } from '../../../../shared/services/stripe-payment.service';
import { AuthState } from '../../../../shared/state/auth.state';
import { FetchAbstractSubscriptionsAction } from '../../actions/fetch-abstract-subscriptions.action';
import { FetchStripePlansAction } from '../../actions/fetch-stripe-plans.action';
import { FetchUserSubscriptionInvoicesAction } from '../../actions/fetch-user-subscription-invoices.action';
import { FetchUserTopUpPaymentsAction } from '../../actions/fetch-user-top-up-payments.action';

import { PaymentState } from '../../payment.state';
import { AbstractSubscription } from '../../../../../../../../common/interfaces/prisma.binding';


@Component({
  selector: 'tt-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(PaymentState.stripePlans) stripePlans$: Observable<StripePlan[]>;
  @Select(PaymentState.abstractSubscriptions) abstractSubscriptions$: Observable<AbstractSubscription[]>;
  @Select(PaymentState.paymentMethods) paymentMethods$: Observable<Data[]>;
  @Select(AuthState.userStripeSubscriptions) userStripeSubscriptions$: Observable<string>;

  stripePlansSubscription: Subscription;
  paymentMethodsSubscription: Subscription;
  userStripeSubscriptionsSubscription: Subscription;
  paymentMethods = [];
  selectedStripePlan: StripePlan;
  userStripeSubscriptions = [];
  realStripeSubscriptions ='';

  constructor(private store: Store,
              private paymentService: StripePaymentService,
              public dialog: MatDialog) {
    this.store.dispatch(new FetchStripePlansAction());
    this.store.dispatch(new FetchUserSubscriptionInvoicesAction());
    this.store.dispatch(new FetchUserTopUpPaymentsAction());
    this.store.dispatch(new FetchAbstractSubscriptionsAction());
  }

  ngOnInit() {
    this.stripePlansSubscription = this.stripePlans$.subscribe(stripePlans => console.log('Plans', stripePlans));
    this.paymentMethodsSubscription = this.paymentMethods$.subscribe(paymentMethods => this.paymentMethods = paymentMethods);
    this.userStripeSubscriptionsSubscription = this.userStripeSubscriptions$
      .subscribe(stripeSubscriptions => {
        if (stripeSubscriptions !== undefined) {
          this.realStripeSubscriptions = stripeSubscriptions;
          // this.userStripeSubscriptions = stripeSubscriptions.map(data => data['items'].data.map(data1 => data1.plan.id));
        }
      })
    ;
  }

  ngAfterViewInit(): void {

  }

  chancelSubscription(planId: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Abo wirklich kündigen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._findSubscriptionByPlanId(planId).forEach(
          subscriptionId => this.paymentService.chancelStripeSubscription(subscriptionId));
      }
    });
  }

  createSubscription(stripePlan: StripePlan) {
    this.selectedStripePlan = stripePlan;
    this.paymentService.createStripeSubscription(stripePlan.planID);
    console.log(this.selectedStripePlan);
  }

  ngOnDestroy(): void {
    this.stripePlansSubscription.unsubscribe();
    this.paymentMethodsSubscription.unsubscribe();
    this.userStripeSubscriptionsSubscription.unsubscribe();
  }

  private _findSubscriptionByPlanId(planId: string): string[] {
    const subscriptionIds = [];
    // this.realStripeSubscriptions.forEach(subscription => {
    //   if (
    //     subscription.items.data.find(plan => {
    //       if (plan.hasOwnProperty('plan')) {
    //         return plan.plan.id === planId;
    //       }
    //     })) {
    //     subscriptionIds.push(subscription.id);
    //   }
    // });
    return subscriptionIds;
  }
}

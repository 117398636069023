import {Routes} from '@angular/router';
import {AdminGuard} from '../../shared/guards/admin.guard';
import {TeamDetailComponent} from './components/team-detail/team-detail.component';
import {TeamOverviewComponent} from './components/team-overview/team-overview.component';

export const TEAM_ROUTES: Routes = [

  {path: 'admin/teams', component: TeamOverviewComponent, canActivate: [AdminGuard]},
  {path: 'admin/teams/add', component: TeamDetailComponent, canActivate: [AdminGuard]},
  {path: 'admin/teams/edit/:id', component: TeamDetailComponent, canActivate: [AdminGuard]},
];

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';

import gql from 'graphql-tag';

export interface Response {
  braintreeClientToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class GetBraintreeClientTokenByContextQuery extends Query<Response> {
  document = gql`
    query braintreeClientToken {
      braintreeClientToken
    }
  `;
}

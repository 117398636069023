import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {StripePaymentService} from '../../../../shared/services/stripe-payment.service';


@Component({
  selector: 'tt-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', {static: true}) cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(private cd: ChangeDetectorRef, private paymentService: StripePaymentService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.card = this.paymentService.elements.create('card', {hidePostalCode: true});

    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy(): void {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({error}) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const {source, error} = await this.paymentService.stripe.createSource(this.card);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      console.log('Success!', source);
      this.paymentService.addCard(source);
      // ...send the token to the your backend to process the charge
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { User } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { AuthState } from '../../../../shared/state/auth.state';
import { FetchUserAction } from '../../actions/fetch-user.action';
import { DeleteUserMutation } from '../../mutations/delete-user.mutation';
import { UserState } from '../../user.state';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tt-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  users;
  displayedColumns: string[] = ['firstname', 'name', 'email', 'actions'];
  dataSource: MatTableDataSource<User>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(UserState.users) users$: Observable<User[]>;
  private _currentUser: User;

  constructor(
    private deleteUserMutation: DeleteUserMutation,
    private store: Store,
    public dialog: MatDialog,
    private logger: NGXLogger,
  ) {}

  ngOnInit() {
    this._currentUser = this.store.selectSnapshot(
      AuthState.userDetails,
    ) as User;

    this.store.dispatch(new FetchUserAction());
    this.users$.subscribe((users) => {
      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteUser(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen User wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteUserMutation.mutate({ id }).subscribe(
          ({ data }) => {
            this.logger.log('got User delete data', data);
            this.store.dispatch(new FetchUserAction());
          },
          (error) => {
            error.graphQLErrors.map(({ message }, i) =>
              this.logger.error(message, i),
            );
          },
        );
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {ElectricMeter} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allElectricMeters: ElectricMeter[];
}

@Injectable({
  providedIn: 'root',
})
export class AllElectricMetersQuery extends Query<Response> {
  document = gql`
      query allElectricMeters {
          electricMeters {
              id
              address
              comment
              type
              serialNumber
              totalKwh
              totalSystemPower
              brand {
                  id
                  name
              }
          }
      }
  `;
}

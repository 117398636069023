import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GetUserByKeycloakIdQuery } from '../queries/get-user-by-keycloakid.query';

@Injectable()
export class AuthService {
  constructor(private getUserByKeycloakIdQuery: GetUserByKeycloakIdQuery) {}

  signIn(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getUserByKeycloakIdQuery
        .watch(
          { keycloakID: token },
          {
            fetchPolicy: 'network-only',
          },
        )
        .valueChanges.subscribe(userData => {
          console.log('Signin', userData.errors);
          resolve(userData.data.user);
        });
    });
  }

  signOut(): Observable<null> {
    return of(null);
  }
}

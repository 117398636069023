import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Terminal } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  terminals: Terminal[];
}

@Injectable({
  providedIn: 'root',
})
export class AllTerminalQuery extends Query<Response> {
  document = gql`
    query terminals {
      terminals {
        devices {
          id
          description
          deviceID
        }
        id
        name
        relaysToSwitch {
          id
          pin
          description
        }
        type
      }
    }
  `;
}

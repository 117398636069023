import {AbstractSubscription, Payment} from '../../../../../../common/interfaces/prisma.binding';
import {Data, StripeInvoice, StripePlan} from '../../../../../../common/interfaces/stripe.bindings';
import {ChargingPaymentMethodInterface} from './interfaces/charging-payment-method.interface';

export class PaymentStateModel {
  abstractSubscriptions: AbstractSubscription[];
  customTopUpValue: number;
  invoices?: StripeInvoice[];
  isBraintreePaymentMethodRequestable: boolean;
  selectedTopUpPaymentMethod: ChargingPaymentMethodInterface;
  selectedSubscriptionPaymentMethod: ChargingPaymentMethodInterface;
  selectedTeamSubscriptionPaymentMethod: ChargingPaymentMethodInterface;
  stripePlans?: StripePlan[];
  topUpValues: number[];
  topUpPayments: Payment[];
  userPaymentMethods?: Data[];
}

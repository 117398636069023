// Auth State Model
import {
  DateTime,
  Image,
  MeterReading,
  Team,
  UserRole,
} from '../../../../../../common/interfaces/prisma.binding';
import { BillomatDtoInterface } from '../../../../../../common/interfaces/billomat-dto.interface';

export class AuthStateModel {
  braintreeClientToken?: string;
  billomatInvoices?: BillomatDtoInterface[];
  invoiceID?: string;
  city?: string;
  createdAt?: DateTime;
  credit?: number;
  currency?: string;
  email?: string;
  firstName?: string;
  housenumber?: string;
  isPro?: boolean;
  id?: string;
  invoices?: Image[];
  keycloakId?: string;
  lastName?: string;
  meterReading?: MeterReading[];
  stripeSubscriptions?: string;
  team?: Team;
  token?: string; // refreshToken?: string;
  updatedAt?: DateTime;
  userRoles?: UserRole[];
  username?: string;
  zip?: string;
}

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { User, UserRole } from '../../../../../../common/interfaces/prisma.binding';

import { AuthService } from '../../shared/services/auth.service';
import { AddUserRole } from './actions/add-user-role.action';
import { FetchUserAction } from './actions/fetch-user.action';
import { AllUsersQuery } from './queries/all-users.query';
import { Injectable } from '@angular/core';

export interface UserStateModel {
  userRoles: UserRole[];
  users: User[];
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    userRoles: [],
    users: [],
  },
})
@Injectable()
export class UserState {
  constructor(private allUsersQuery: AllUsersQuery, private authServie: AuthService) {
  }

  @Selector()
  static userRoles(state: UserStateModel) {
    return state.userRoles;
  }

  @Selector()
  static users(state: UserStateModel) {
    return state.users;
  }

  @Action(AddUserRole)
  addUserRoles(ctx: StateContext<UserStateModel>, action: AddUserRole) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userRoles: [
        ...state.userRoles,
        action.userRole,
      ],
    });
  }

  @Action(FetchUserAction)
  async getUsers({ patchState }: StateContext<UserStateModel>, action: FetchUserAction) {
    return new Promise(async (resolve, reject) => {
      this.allUsersQuery.watch( {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({ data, loading }: { data: any, loading: boolean }) => resolve(patchState({ users: data.users })));
    });
  }

  // getUsers(ctx: StateContext<UserStateModel>, action: FetchUserAction) {
  //   return this.allUsersQuery.watch({}, {
  //     fetchPolicy: 'network-only'
  //   }).valueChanges.pipe( map(({ data }) => {
  //     console.log('ffs', data);
  //     const state = ctx.getState();
  //     ctx.setState({
  //       ...state,
  //       users: [
  //         ...state.users,
  //         data,
  //       ]
  //     });
  //   }));
  // }


}

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { AddMessageToSnackbarQueueAction } from '../actions/add-message-to-snackbar-queue.action';
import { DecreaseHttpCallCount } from '../actions/decrease-http-call-count.action';
import { HideSpinner } from '../actions/hide-spinner.action';
import { IncreaseHttpCallCount } from '../actions/increase-http-call-count.action';
import { ShowMessageFromSnackbarQueueAction } from '../actions/show-message-from-snackbar-queue.action';
import { ShowSpinner } from '../actions/show-spinner.action';
import { ToggleSidebar } from '../actions/toggle-sidebar.action';
import { UpdateBraintreeDropInAction } from '../actions/update-braintree-drop-in.action';
import { UiStateModel } from '../models/ui-state.model';
import { v4 } from 'uuid';
import { Injectable } from '@angular/core';

@State<UiStateModel>({
  name: 'ui',
  defaults: {
    httpCallCount: 0,
    showSidebar: true,
    showSpinner: false,
    snackbarQueue: [],
    updateBraintreeDropIn: false,
  },
})
@Injectable()
export class UiState {
  @Selector()
  static showSidebar(state: UiStateModel) {
    return state.showSidebar;
  }

  @Selector()
  static showSpinner(state: UiStateModel) {
    return state.showSpinner;
  }

  @Selector()
  static httpCallCount(state: UiStateModel) {
    return state.httpCallCount;
  }

  @Selector()
  static updateBraintreeDropIn(state: UiStateModel) {
    return state.updateBraintreeDropIn;
  }

  @Selector()
  static getSnackbarQueue(state: UiStateModel) {
    return state.snackbarQueue;
  }

  @Action(ToggleSidebar)
  toggleSidebar(ctx: StateContext<UiStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showSidebar: !state.showSidebar,
    });
  }

  @Action(ShowSpinner)
  toggleShow(ctx: StateContext<UiStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showSpinner: true,
    });
  }

  @Action(HideSpinner)
  toggleHide(ctx: StateContext<UiStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showSpinner: false,
    });
  }

  @Action(UpdateBraintreeDropInAction)
  updateBraintreeDropInAction(
    { patchState }: StateContext<UiStateModel>,
    action: UpdateBraintreeDropInAction,
  ) {
    patchState({ updateBraintreeDropIn: action.updateState });
  }

  @Action(ShowMessageFromSnackbarQueueAction)
  showMessageFromSnackbarQueue(
    ctx: StateContext<UiStateModel>,
    action: ShowMessageFromSnackbarQueueAction,
  ) {
    const state = ctx.getState();
    const messageToShow = state.snackbarQueue.find(
      message => message.id === action.messageId,
    );
  }

  @Action(AddMessageToSnackbarQueueAction)
  addMessageToSnackbarQueue(
    ctx: StateContext<UiStateModel>,
    action: AddMessageToSnackbarQueueAction,
  ) {
    const state = ctx.getState();
    // generate uuid to find message in queue
    action.snackbarMessage.id = v4();
    ctx.patchState({
      snackbarQueue: [...state.snackbarQueue, action.snackbarMessage],
    });
  }

  @Action(IncreaseHttpCallCount)
  increaseHttpCallCount(ctx: StateContext<UiStateModel>) {
    const state = ctx.getState();

    let newCallCount = state.httpCallCount;
    newCallCount++;
    ctx.setState({
      ...state,
      httpCallCount: newCallCount,
    });
    if (!state.showSpinner && newCallCount > 0) {
      return ctx.dispatch(new ShowSpinner());
    }
  }

  @Action(DecreaseHttpCallCount)
  decreaseHttpCallCount(ctx: StateContext<UiStateModel>) {
    let newCallCount = ctx.getState().httpCallCount;
    if (ctx.getState().httpCallCount > 0) {
      newCallCount--;
    } else {
      newCallCount = 0;
    }
    const state = ctx.getState();
    ctx.setState({
      ...state,
      httpCallCount: newCallCount,
    });
    if (newCallCount === 0) {
      return ctx.dispatch(new HideSpinner());
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MyAccountComponent } from './components/my-account/my-account.component';

@NgModule({
  declarations: [
    MyAccountComponent,

  ],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,

  ],
  entryComponents: [],
})
export class MyAccountModule {
}

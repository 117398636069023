import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';
import { User } from '../../../../../../common/interfaces/prisma.binding';
import { UserRoleEnum } from '../../../../../../common/enums/user-role.enum';
import { Injectable } from '@angular/core';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private _store: Store, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this._store.selectSnapshot(AuthState.userDetails) as User;
    const hasAdminRole = () => currentUser.userRoles.some((role => role.name === UserRoleEnum.ADMIN));
    if (!hasAdminRole()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}

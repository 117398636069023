import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserPinMutation extends Mutation {
  document = gql`
    mutation updateUser(
      $id: ID!,
      $pin:String,
    )
    {
      updateUser(where:{
        id:$id
      } data:{
        pin:$pin,
      }) {
        id
      }
    }
  `;
}

import {Component, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';

import {Observable, Subscription} from 'rxjs';
import {SetCustomTopUpValueAction} from '../../actions/set-custom-top-up-value.action';
import {ChargingPaymentMethodInterface} from '../../interfaces/charging-payment-method.interface';
import {PaymentState} from '../../payment.state';

@Component({
  selector: 'tt-top-up-values',
  templateUrl: './top-up-values.component.html',
  styleUrls: ['./top-up-values.component.scss'],
})
export class TopUpValuesComponent implements OnDestroy {
  @Select(PaymentState.topUpValues) topUpValues$: Observable<number[]>;
  @Select(PaymentState.customTopUpValue) topUpValue$: Observable<number>;
  @Select(PaymentState.selectedTopUpPaymentMethod) selectedTopUpPaymentMethod$: Observable<ChargingPaymentMethodInterface>;

  topUpSubscription: Subscription;
  topUpValue: number;

  constructor(private store: Store) {
    this.topUpValue = this.store.selectSnapshot(PaymentState.customTopUpValue) / 100;
  }

  checkOwnValue() {
    if (this.topUpValue < 0.5) {
      this.topUpValue = 0.5;
      this.store.dispatch(new SetCustomTopUpValueAction(this.topUpValue));
    }
  }

  setTopUpValue(value: number) {
    this.store.dispatch(new SetCustomTopUpValueAction(value));

  }

  setCustomTopUpValue(event) {
    this.store.dispatch(new SetCustomTopUpValueAction(event.target.value));
  }

  ngOnDestroy(): void {
    if (this.topUpSubscription !== undefined) {
      this.topUpSubscription.unsubscribe();
    }
  }
}

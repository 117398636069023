import {Routes} from '@angular/router';
import {AdminGuard} from '../../shared/guards/admin.guard';

import {PaymentsComponent} from './components/payments/payments.component';

export const PAYMENTS_ROUTES: Routes = [

  {path: 'payments', component: PaymentsComponent, canActivate: [AdminGuard]},

];

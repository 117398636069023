import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateRelayMutation extends Mutation {
  document = gql`
    mutation updateRelay($id:ID!, $description: String!, $pin:Int!, $users: UserUpdateManyInput, $team: TeamUpdateOneInput,$connectedGPIODevice: GPIODeviceUpdateOneWithoutConnectedRelayInput,$gpioController:GPIOController, $autoRelease: Boolean, $autoReleaseTimeInMs: Int,$autoToggle:Boolean ) {
      updateRelay(where:{
        id:$id
      }
        data: {
          pin: $pin
          users:$users,
          team: $team
          connectedGPIODevice: $connectedGPIODevice
          description:$description
          gpioController: $gpioController
          autoRelease: $autoRelease
          autoReleaseTimeInMs:$autoReleaseTimeInMs
          autoToggle: $autoToggle
        }
      ) {
        id
        description
      }
    }
  `;
}

import {Action, Selector, State, StateContext} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';
import {Area} from '../../../../../../common/interfaces/prisma.binding';
import {AuthService} from '../../shared/services/auth.service';
import {FetchAreasAction} from './actions/fetch-areas.action';
import {FetchUserAreasAction} from './actions/fetch-user-areas.action';
import {AllAreasQuery} from './queries/all-areas.query';
import {OwnAreasQuery} from './queries/own-areas.query';
import { Injectable } from '@angular/core';


export interface AreaModel {
  areas: Area[];
  userAreas: Area[];

}
@Injectable()
@State<AreaModel>({
  name: 'areas',
  defaults: {
    areas: [],
    userAreas: [],
  },
})

export class AreaState {
  constructor(private authService: AuthService,
              private allAreasQuery: AllAreasQuery,
              private userAreasQuery: OwnAreasQuery,
              private logger: NGXLogger) {
  }

  @Selector()
  static areas(state: AreaModel) {
    return state.areas;
  }

  @Selector()
  static userAreas(state: AreaModel) {
    return state.userAreas;
  }

  @Action(FetchAreasAction)
  async getAreas({patchState}: StateContext<AreaModel>, action: FetchAreasAction) {
    return new Promise(async (resolve, reject) => {
      this.allAreasQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({areas: data.areas})));
    });
  }

  @Action(FetchUserAreasAction)
  async getUserAreas({patchState}: StateContext<AreaModel>, action: FetchUserAreasAction) {
    return new Promise(async (resolve, reject) => {
      this.userAreasQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({userAreas: data.getOwnAreas})));
    });
  }

}

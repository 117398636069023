import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateBarcodeListItemMutation extends Mutation {
  document = gql`
    mutation updateBarcodeList($id: ID!,$ean: String, $data:String,$price: Float,$internalId: String) {
      updateBarcodeList(
        where: { id:$id}
        data: {
          ean:$ean
          data:$data
          price:$price
          internalId: $internalId
        }){
        id
      }
    }
  `;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookedTime',
})
export class BookedTimePipe implements PipeTransform {
  public transform(seconds: number): string {
    const hours = Math.floor(seconds / 60 / 60);
    const minutes = Math.floor(seconds / 60) - hours * 60;
    return `${hours}:${minutes.toString().padEnd(2, '0')}`;
  }
}

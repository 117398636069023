import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetBraintreeClientTokenByContextQuery } from '../queries/get-braintree-client-token-by-context.query';

@Injectable()
export class BraintreePaymentService {
  constructor(
    private getBraintreeClientTokenByContextQuery: GetBraintreeClientTokenByContextQuery,
  ) {}

  private _braintreeInstance: any = null;

  get braintreeInstance() {
    return this._braintreeInstance;
  }

  set braintreeInstance(instance) {
    this._braintreeInstance = instance;
  }

  getBraintreeClientTokenByContext(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getBraintreeClientTokenByContextQuery
        .watch({
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe((userData) =>
          resolve(userData.data.braintreeClientToken),
        );
    });
  }
}

import {Routes} from '@angular/router';
import {AdminGuard} from '../../shared/guards/admin.guard';
import {UserDetailComponent} from './components/user-detail/user-detail.component';
import {OverviewComponent} from './components/overview/overview.component';

export const USER_ROUTES: Routes = [

  { path: 'admin/user', component: OverviewComponent, canActivate: [AdminGuard] },
  {path: 'admin/user/add', component: UserDetailComponent, canActivate: [AdminGuard]},
  {path: 'admin/user/edit/:id', component: UserDetailComponent, canActivate: [AdminGuard]},
];

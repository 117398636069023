import {Routes} from '@angular/router';
import {AreaDetailComponent} from './components/area-detail/area-detail.component';
import {AreaOverviewComponent} from './components/area-overview/area-overview.component';

export const AREA_ROUTES: Routes = [

  {path: 'admin/area', component: AreaOverviewComponent},
  {path: 'admin/area/add', component: AreaDetailComponent},
  {path: 'admin/area/edit/:id', component: AreaDetailComponent},
];

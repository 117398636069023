import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import {
  Container,
  MeterReading,
  MeterReadingCreateInput,
  MeterReadingCreateManyWithoutUserInput,
  MeterReadingUpdateManyWithoutUserInput,
  Team,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchContainerAction } from '../../../container/actions/fetch-container.action';
import { ContainerState } from '../../../container/container.state';
import { FetchTeamsAction } from '../../../team/actions/fetch-teams.action';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { UserState } from '../../../user/user.state';
import { CreateTeamMutation } from '../../mutations/create-team-mutation';
import { UpdateTeamMutation } from '../../mutations/update-team-mutation';
import { TeamState } from '../../team.state';

@Component({
  selector: 'tt-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss'],
})
export class TeamDetailComponent implements OnInit {
  editMode = false;
  teams: Team[];
  users;
  currentTeam: Team;
  disconnectUsers = [];
  container;
  disconnectContainer = [];
  initiallySelectedUsers$ = new Subject<[any]>();
  initiallySelectedContainer$ = new Subject<[any]>();
  initiallySelectedMeterReading$ = new Subject<[any]>();
  electricMeterReading = new Set<MeterReading>();
  disconnectedElectricMeterReading = new Set<MeterReading>();
  temporaryElectricMeterReading: MeterReading;

  teamForm: FormGroup;
  @Select(UserState.users) users$: Observable<User[]>;
  @Select(ContainerState.containers) container$: Observable<Container[]>;
  @Select(TeamState.teams) teams$: Observable<Team[]>;
  private _id: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private createTeamMutation: CreateTeamMutation,
    private updateTeamMutation: UpdateTeamMutation,
  ) {}

  ngOnInit() {
    this.teamForm = this.formBuilder.group({
      name: ['', Validators.required],
      container: [''],
      users: [''],
      meterReadings: [''],
    });
    this.store
      .dispatch([
        new FetchContainerAction(),
        new FetchUserAction(),
        new FetchTeamsAction(),
      ])
      .subscribe(() => {
        this.route.params.subscribe((params) => {
          if (params['id'] !== undefined) {
            this.editMode = true;
            this._id = params['id'];
            this._populateForm(this._id);
          }
        });
      });
  }

  createTeam() {
    const meterReadings: MeterReadingCreateManyWithoutUserInput = this._createReadingsFromInput(
      this.electricMeterReading,
    );

    this.createTeamMutation
      .mutate({
        name: this.teamForm.value['name'].toString(),
        users: this.users,
        container: this.container,
        meterReadings: meterReadings,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create tram', data);
          // refresh store
          this.store.dispatch(new FetchContainerAction());
          this.router.navigate([`/admin/teams/edit/${data['createTeam'].id}`]);
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  updateTeam() {
    // constructiong the disconnects
    if (this.users.disconnect === undefined) {
      this.currentTeam.users.forEach((user) => {
        if (!this.users.connect.find((userId) => userId.id === user.id)) {
          this.disconnectUsers.push({ id: user.id });
        }
      });
      this.users = { ...this.users, disconnect: this.disconnectUsers };
    }
    if (this.container.disconnect === undefined) {
      this.currentTeam.container.forEach((user) => {
        if (!this.container.connect.find((userId) => userId.id === user.id)) {
          this.disconnectContainer.push({ id: user.id });
        }
      });
      this.container = {
        ...this.container,
        disconnect: this.disconnectContainer,
      };
    }
    const updateMeterReadings: MeterReadingUpdateManyWithoutUserInput = {
      disconnect: Array.from(this.disconnectedElectricMeterReading).map(
        (reading) => {
          return { id: reading.id };
        },
      ),
      connect: Array.from(this.electricMeterReading)
        .filter((reading) => (reading as object).hasOwnProperty('id'))
        .map((reading) => {
          return { id: reading.id };
        }),
      create: Array.from(this.electricMeterReading)
        .filter((reading) => !(reading as object).hasOwnProperty('id'))
        .map((reading) => {
          return {
            meterReading: reading.meterReading,
            entryDate: reading.entryDate,
            kwhPriceOnReading: reading.kwhPriceOnReading,
            meter: { connect: { id: reading.meter.id } },
          };
        }),
    };
    console.log(updateMeterReadings);
    this.updateTeamMutation
      .mutate({
        id: this._id,
        name: this.teamForm.value['name'].toString(),
        users: this.users,
        container: this.container,
        meterReadings: updateMeterReadings,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update team', data);
          // refresh store
          this.store.dispatch(new FetchTeamsAction());
        },
        (error) => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  onContainerSelectionChange(event) {
    if (event.length > 0) {
      this.container = {
        connect: event.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      if (this.currentTeam) {
        this.container = {
          disconnect: this.currentTeam.container.map((singleEvent) => {
            return {
              id: singleEvent.id,
            };
          }),
        };
      }
    }
  }

  onAddElectricMeterReading(meterReading: MeterReading) {
    if (meterReading === undefined) {
      this.temporaryElectricMeterReading = undefined;
    } else {
      this.temporaryElectricMeterReading = meterReading;
    }
  }

  onAddElectricMeterReadingClick() {
    this.electricMeterReading.add(
      Object.assign({}, this.temporaryElectricMeterReading),
    );
    this.temporaryElectricMeterReading = undefined;
  }

  onDeleteElectricMeterReadingClick(meterReading: MeterReading) {
    if ((meterReading as object).hasOwnProperty('id')) {
      this.disconnectedElectricMeterReading.add(meterReading);
    }
    this.electricMeterReading.delete(meterReading);
  }

  onUserSelectionChange(event) {
    if (event.length > 0) {
      this.users = {
        connect: event.map((singleEvent) => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      if (this.currentTeam) {
        // @ts-ignore
        this.users = {
          disconnect: this.currentTeam.users.map((singleEvent) => {
            return {
              id: singleEvent.id,
            };
          }),
        };
      }
    }
  }

  private _createReadingsFromInput(
    readings: Set<MeterReading> = new Set(),
  ): MeterReadingCreateManyWithoutUserInput {
    const tempReturnArray: MeterReadingCreateInput[] = [];
    readings.forEach((reading) => {
      tempReturnArray.push({
        meterReading: reading.meterReading,
        meter: { connect: { id: reading.meter.id } },
      });
    });
    return { create: tempReturnArray };
  }

  private _populateForm(id: string) {
    this.teams$.subscribe((teams) => {
      // @ts-ignore
      this.currentTeam = teams.find((team) => team.id === id);
      if (this.currentTeam) {
        if (this.currentTeam.users) {
          this.users = {
            connect: this.currentTeam.users.map((singleUser) => {
              return {
                id: singleUser.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedUsers$.next(this.currentTeam.users);
        }
        if (this.currentTeam.container) {
          this.container = {
            connect: this.currentTeam.container.map((singleContainer) => {
              return {
                id: singleContainer.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedContainer$.next(this.currentTeam.container);
        }
        if (this.currentTeam.meterReadings) {
          // populate meter readings
          (this.currentTeam.meterReadings as Array<any>).forEach(
            (meterReading: MeterReading) => {
              this.electricMeterReading.add(meterReading);
            },
          );
        }

        this.teamForm.patchValue({
          name: this.currentTeam.name,
          users: this.currentTeam.users,
          container: this.currentTeam.container,
        });
      }
    });
  }
}

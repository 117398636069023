import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {OAuthResourceServerErrorHandler, OAuthStorage} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {

  constructor(private store: Store, private authStorage: OAuthStorage,
              private errorHandler: OAuthResourceServerErrorHandler,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // we check if there is no auth header
    if (!req.headers.has('authorization')) {
      const token = this.authStorage.getItem('access_token');
      const header = 'Bearer ' + token;

      const headers = req.headers
        .set('Authorization', header);
      req = req.clone({headers});
    }
    return next.handle(req);
  }
}

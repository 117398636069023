import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {MyWorkshopComponent} from './components/my-workshop/my-workshop.component';

@NgModule({
  declarations: [
    MyWorkshopComponent,

  ],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,

  ],
  entryComponents: [],
})
export class MyWorkshopModule {
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteElectricMeterMutation extends Mutation {
  document = gql`
      mutation deleteElectricMeter($id: ID!) {
          deleteElectricMeter(
              where: { id: $id }) {id}
      }
  `;
}

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Area} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allAreas: Area[];
}

@Injectable({
  providedIn: 'root',
})
export class AllAreasQuery extends Query<Response> {
  document = gql`
      query allAreas {
          areas {
              id
              description
              squareMeter
              pricePerSquareMeter
              team {
                  id
                  name
              }
              user {
                  id
                  lastName
                  firstName
                  username
              }
              meter {
                  id
                  address
              }
          }
      }
  `;
}

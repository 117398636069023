import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Select, Store} from '@ngxs/store';
import {Subscription} from 'apollo-client/util/Observable';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {Container} from '../../../../../../../../common/interfaces/prisma.binding';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {FetchContainerAction} from '../../actions/fetch-container.action';
import {ContainerState} from '../../container.state';
import {DeleteContainerMutation} from '../../mutations/delete-container.mutation';
import {AllContainerQuery} from '../../queries/all-container.query';

@Component({
  selector: 'tt-container-overview',
  templateUrl: './container-overview.component.html',
  styleUrls: ['./container-overview.component.scss'],
})
export class ContainerOverviewComponent implements OnInit {
  container: Subscription;
  displayedColumns: string[] = ['nummer', 'actions'];
  dataSource: MatTableDataSource<Container>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Select(ContainerState.containers) containers$: Observable<Container[]>;

  constructor(private allContainerQuery: AllContainerQuery,
              private logger: NGXLogger,
              private store: Store,
              private deleteContainerMutation: DeleteContainerMutation,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.store
      .dispatch(new FetchContainerAction());
    this.containers$.subscribe(containers => {
      this.dataSource = new MatTableDataSource(containers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteContainer(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Diesen Container wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteContainerMutation.mutate({id}).subscribe(({data}) => {
          this.logger.log('got Container delete data', data);
          this.store
            .dispatch(new FetchContainerAction());
        }, (error) => {
          error.graphQLErrors.map(({message}, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });

  }

  getFloat(number: string) {
    return parseFloat(number);
  }
}

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { SignIn } from '../actions/sign-in.action';
import { SignOut } from '../actions/sign-out.action';
import { UpdateUserCredit } from '../actions/update-user-credit.action';
import { AuthStateModel } from '../models/auth-state.model';
import { AuthService } from '../services/auth.service';
import { BraintreePaymentService } from '../services/braintree-payment.service';
import { StripePaymentService } from '../services/stripe-payment.service';
import { Injectable } from '@angular/core';
import { BillomatService } from '../services/billomat.service';
import { BillomatDtoInterface } from '../../../../../../common/interfaces/billomat-dto.interface';
import { UserRoleEnum } from '../../../../../../common/enums/user-role.enum';

@State<AuthStateModel>({
  name: 'loggedinUser', // the name of our state
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private logger: NGXLogger,
    private stripePaymentService: StripePaymentService,
    private braintreePaymentService: BraintreePaymentService,
    private billomatService: BillomatService,
  ) {}

  @Selector()
  static braintreeClientToken(state: AuthStateModel) {
    return state.braintreeClientToken;
  }

  @Selector()
  static billomatInvoices(state: AuthStateModel): BillomatDtoInterface[] {
    return state.billomatInvoices;
  }

  @Selector()
  static userCredit(state: AuthStateModel) {
    return state.credit;
  }

  @Selector()
  static userCurrency(state: AuthStateModel) {
    return state.currency;
  }

  @Selector()
  static token(state: AuthStateModel) {
    return state.token;
  }

  @Selector()
  static userInvoices(state: AuthStateModel) {
    return state.invoices;
  }

  @Selector()
  static meterReadings(state: AuthStateModel) {
    return state.meterReading;
  }

  @Selector()
  static userDetails(state: AuthStateModel) {
    return state;
  }

  @Selector()
  static userStripeSubscriptions(state: AuthStateModel) {
    return state.stripeSubscriptions;
  }

  @Selector()
  static invoiceID(state: AuthStateModel) {
    return state.invoiceID;
  }

  @Selector()
  static userTeam(state: AuthStateModel) {
    return state.team;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.userRoles.some((role) => role.name === UserRoleEnum.ADMIN);
  }

  @Action(SignIn)
  async login(
    { patchState }: StateContext<AuthStateModel>,
    { token, keycloakId }: SignIn,
  ) {
    let billomatInvoices = [];
    const userData = await this.authService.signIn(keycloakId);
    const stripeCustomer = await this.stripePaymentService.getStripeUserByContext();
    const braintreeClientToken = await this.braintreePaymentService.getBraintreeClientTokenByContext();
    billomatInvoices = await this.billomatService.getUsersInvoicesByBillomatclientNumber(
      userData.invoiceID,
    );

    this.logger.debug('Userdata', userData);
    patchState({
      braintreeClientToken,
      billomatInvoices,
      credit: userData.credit,
      currency: userData.currency,
      email: userData.email || '',
      firstName: userData.firstName,
      invoiceID: userData.invoiceID,
      userRoles: userData.userRoles,
      city: userData.city,
      username: userData.username,
      id: userData.id,
      invoices: userData.invoices,
      keycloakId: userData.keycloakID,
      lastName: userData.lastName,
      meterReading: userData.meterMeterReading,
      stripeSubscriptions: stripeCustomer?.subscriptions?.data,
      team: userData.team,
      token,
    });
  }

  @Action(SignOut)
  logout({ setState, getState }: StateContext<AuthStateModel>) {
    const { token } = getState();
    return this.authService.signOut().pipe(
      tap((_) => {
        setState({});
      }),
    );
  }

  @Action(UpdateUserCredit)
  updateUserCredit(
    { patchState }: StateContext<AuthStateModel>,
    { newCredit }: UpdateUserCredit,
  ) {
    patchState({ credit: newCredit });
  }
}

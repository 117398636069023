import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tt-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss'],
})
export class CreditComponent implements OnInit {
  @Input()
  credit = 0;
  @Input()
  currency = 'EUR';

  constructor() {
  }

  ngOnInit() {
  }

}

import { Routes } from '@angular/router';
import { TerminalOverviewComponent } from './components/terminal-overview/terminal-overview.component';
import { TerminalDetailComponent } from './components/terminal-detail/terminal-detail.component';


export const TERMINAL_ROUTES: Routes = [

  { path: 'admin/terminal', component: TerminalOverviewComponent },
  { path: 'admin/terminal/add', component: TerminalDetailComponent },
  { path: 'admin/terminal/edit/:id', component: TerminalDetailComponent }
];

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { StripePlan } from '../../../../../../../../common/interfaces/stripe.bindings';

@Component({
  selector: 'tt-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent {
  @Input()
  plans: Observable<StripePlan[]>;
  @Input()
  enabled = true;
  @Input()
  userSubscriptions = [];
  @Output()
  chancelPlan = new EventEmitter<string>();
  @Output()
  selectedPlan = new EventEmitter<StripePlan>();


  chancelSubscription(planId: string) {
    this.chancelPlan.emit(planId);
  }

  selectPlan(plan: StripePlan) {
    this.selectedPlan.emit(plan);
  }

  checkIfAlreadySubscribed(planID: string): boolean {
    if (this.userSubscriptions.length > 0) {
      return this.userSubscriptions[0].includes(planID);
    }
    return false;
  }

}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreatePaypalPaymentMutation extends Mutation {
  document = gql`
    mutation createPaypalPayment($amount: String!,$currency:String!) {
      createPaypalPayment(amount: $amount,currency:$currency)
    }
  `;
}

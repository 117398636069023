import {Injectable} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../common/angular/environments/environment';
import {FetchToolTimeSettingsAction} from './modules/admin/tool-time-settings/actions/fetch-tool-time-settings.action';
import {AddUserRole} from './modules/admin/user/actions/add-user-role.action';
import {UserRolesQuery} from './modules/admin/user/queries/user-roles.query';
import {SignIn} from './modules/shared/actions/sign-in.action';
import {SignOut} from './modules/shared/actions/sign-out.action';
import {SnackbarComponent} from './modules/shared/components/snackbar/snackbar.component';
import {ESnackBarEnum} from './modules/shared/enums/e-snack-bar.enum';
import {ISnackbarData} from './modules/shared/interfaces/snackbar-data.interface';
import {UiState} from './modules/shared/state/ui.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';


@Injectable()
export class AppInitService {
  authConfig: AuthConfig = environment.authConfig;
  @Select(UiState.getSnackbarQueue) snackbarQueue$: Observable<ISnackbarData[]>;

  constructor(private oauthService: OAuthService,
              private userRolesQuery: UserRolesQuery,
              private store: Store,
              public snackBar: MatSnackBar) {
  }

  Init() {
    return new Promise<void>((resolve, reject) => {
      this._configureWithNewConfigApi().then((data) => {
        if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
          this.store.dispatch(new SignOut()).subscribe(success => {
          }, error => {
          });
          // redirect to Keycloak Login Page
          this.oauthService.initCodeFlow();
        }
        // if we already signed in with keycloak put everything in store
        let keycloakId = '';
        let authToken = '';
        const claim = this.oauthService.getIdentityClaims();
        if (claim !== null) {
          keycloakId = claim['sub'];
          authToken = this.oauthService.getAccessToken();
        }
        if (keycloakId !== '') {
          this.store
            .dispatch(new SignIn(keycloakId, authToken))
            .subscribe(success => {
              this._fillStoreWithDefaults();
              resolve();
            }, error => {
            });
        }
      });
      this.snackbarQueue$.subscribe(snackbarQueue => {
        if (snackbarQueue.length > 0) {
          const {title, message, snackbarType, id} = snackbarQueue[snackbarQueue.length - 1];
          this._showSnackbar(title, message, snackbarType, id);
        }
      });
    });
  }


// This api will come in the next version
  private _configureWithNewConfigApi(): Promise<any> {
    this.oauthService.configure(this.authConfig);

    // this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // Optional
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(e => {
      // tslint:disable-next-line:no-console
      console.debug('oauth/oidc event', e);
    });


    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated'))
      .subscribe(e => {
        // tslint:disable-next-line:no-console
        console.debug('Your session has been terminated!');
        this.store.dispatch(new SignOut()).subscribe(success => {
        }, error => {
        });
      });
    // This happens after login
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(e => {
        console.log('Token recieved');
        // this.oauthService.loadUserProfile().then(_ => {
        //   // put everything into store
        //   const claim = this.oauthService.getIdentityClaims();
        //   this.store
        //     .dispatch(new SignIn(claim['sub'], this.oauthService.getAccessToken()))
        //     .subscribe(success => {}, error => {});
        // });
      });
    return this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  private _showSnackbar(title: string, message: string, snackbarType: ESnackBarEnum, id: string) {
    const data: ISnackbarData = {
      message,
      title,
      snackbarType,
      id,
    };


    this.snackBar.openFromComponent(SnackbarComponent, {
      data,
      horizontalPosition: 'right',
      // panelClass: snackbarType,
      verticalPosition: 'top',
      // duration: 10000,
    });

  }

  private _fillStoreWithDefaults() {
    this.userRolesQuery.watch({}, {
        fetchPolicy: 'network-only',
      })
      .valueChanges
      .subscribe(({data, loading}: { data: any, loading: boolean }) => {
        data.userRoles.forEach(userRole => this.store.dispatch(new AddUserRole(userRole)));
      });
    this.store.dispatch(new FetchToolTimeSettingsAction());
  }

}

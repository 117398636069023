import {Routes} from '@angular/router';
import {GpioDevicesDetailComponent} from './components/gpio-devices-detail/gpio-devices-detail.component';
import {GpioDevicesOverviewComponent} from './components/gpio-devices-overview/gpio-devices-overview.component';

export const GPIO_DEVICES_ROUTES: Routes = [

  {path: 'admin/gpio-devices', component: GpioDevicesOverviewComponent},
  {path: 'admin/gpio-devices/add', component: GpioDevicesDetailComponent},
  {path: 'admin/gpio-devices/edit/:id', component: GpioDevicesDetailComponent},
];

import {AuthConfig} from 'angular-oauth2-oidc';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: 'https://auth.dev.tooltime.berlin/auth/realms/TOOL_TIME',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/home',
  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'WEB_CLIENT',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email',
  waitForTokenInMsec: 0,
};

export const environment = {
  production: true,
  apiURL: 'https://api.dev.tooltime.berlin/graphql',
  apiWSURL: 'wss://api.dev.tooltime.berlin/graphql',
  imageBackend: 'https://api.dev.tooltime.berlin',
  authConfig,
};

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {PaymentMethodDirective} from '../../directives/payment-method.directive';
import {IAbstractPaymentMethod} from '../../interfaces/abstract-payment-method.interface';
import {ChargingPaymentMethodInterface} from '../../interfaces/charging-payment-method.interface';
import {PaymentState} from '../../payment.state';

@Component({
  selector: 'tt-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectPaymentMethodComponent implements OnInit, AfterViewInit {

  @Input()
  activePaymentMethods: IAbstractPaymentMethod[];

  @Output()
  paymentMethodChanged = new EventEmitter<ChargingPaymentMethodInterface>();

  selectedPaymentMethodModel: any;
  @ViewChildren(PaymentMethodDirective) paymentMethodHosts: QueryList<PaymentMethodDirective>;
  @ViewChildren('radioButton') radioButtons: QueryList<MatRadioButton>;


  constructor(private store: Store,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // we take the ng-template from the template that is stored as ViewChilds an use the index in the array to put
    // the actual components there
    this.paymentMethodHosts.forEach((paymentMethodHost: PaymentMethodDirective, index: number) => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.activePaymentMethods[index].component);
      // we create the component and store the create component into the array
      this.activePaymentMethods[index].componentRef = paymentMethodHost.viewContainerRef.createComponent(componentFactory);
      this.activePaymentMethods[index].componentRef.instance.paymentMethodDisabled$.subscribe(paymentMethodDisabled => {
        this.radioButtons.toArray()[index].disabled = paymentMethodDisabled;
      });
      // if one of the payment method components changes we emit the change through an output
      this.activePaymentMethods[index].componentRef.instance.paymentConfiguration$.subscribe(paymentConfiguration => {
        this.paymentMethodChanged.emit(paymentConfiguration);
      });
    });
  }

  /**
   * is called if radio button group is changed
   */
  changePaymentMethod() {
    // disable all components in list
    this.activePaymentMethods.forEach(paymentMethod => paymentMethod.componentRef.instance.disabled$.next(true));
    // enable only the selected one
    this.activePaymentMethods[this.selectedPaymentMethodModel].componentRef.instance.disabled$.next(false);
  }
}

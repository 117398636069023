import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTerminalMutation extends Mutation {
  document = gql`
    mutation createTerminal($name: String!,$type:TerminalType!,$devices: GPIODeviceCreateManyWithoutTerminalInput, $relaysToSwitch: RelayCreateManyInput) {
      createTerminal(
        data: {
          name: $name
          type:$type
          devices: $devices
          relaysToSwitch: $relaysToSwitch
        }
      ) {
        id
        name
      }
    }
  `;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngxs/store';
import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {NGXLogger} from 'ngx-logger';
import {AuthState} from '../../state/auth.state';

@Component({
  selector: 'tt-image-drop',
  templateUrl: './image-drop.component.html',
  styleUrls: ['./image-drop.component.scss'],
})
export class ImageDropComponent implements OnInit {

  @Input()
  config!: DropzoneConfigInterface;

  @Input()
  message = 'Dokument(e) hier her ziehen oder klicken';

  @Output()
  onUploadInit = new EventEmitter();
  @Output()
  onUploadError = new EventEmitter();
  @Output()
  onUploadSuccess = new EventEmitter();
  @Output()
  onRemoveFile = new EventEmitter();

  constructor(private store: Store, private logger: NGXLogger) {
  }

  ngOnInit() {
    const token = this.store.selectSnapshot(AuthState.token);
    this.config.headers = {Authorization: 'Bearer ' + token};
  }

  _onUploadInit(args: any): void {
    this.onUploadInit.emit(args);
  }

  _onUploadError(args: any): void {
    this.onUploadError.emit(args);
  }

  _onUploadSuccess(args: any): void {
    this.onUploadSuccess.emit(args);
  }

  _onRemoveFile(args: any): void {
    this.onRemoveFile.emit(args);
  }

}

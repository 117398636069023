import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  MatProgressSpinnerModule,
  MatSpinner,
} from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContainerSelectComponent } from './components/container-select/container-select.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { MeterReadingWidgetComponent } from './components/meter-reading-widget/meter-reading-widget.component';
import { S3ImageComponent } from './components/s3-image/s3-image.component';
import { SelectEntityWidgetComponent } from './components/select-entity-widget/select-entity-widget.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthService } from './services/auth.service';
import { BraintreePaymentService } from './services/braintree-payment.service';
import { StripePaymentService } from './services/stripe-payment.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { ExtractKeysFromEnumPipe } from './pipes/extract-keys-from-enum.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BillomatService } from './services/billomat.service';
import { BookedTimePipe } from './pipes/booked-time.pipe';

@NgModule({
  imports: [
    MatExpansionModule,
    BrowserAnimationsModule,
    CommonModule,
    ClipboardModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableExporterModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    DropzoneModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    MatAutocompleteModule,
  ],
  exports: [
    ExtractKeysFromEnumPipe,
    BookedTimePipe,
    MatExpansionModule,
    BrowserAnimationsModule,
    CommonModule,
    ClipboardModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatInputModule,
    DropzoneModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatTableExporterModule,
    ImageDropComponent,
    S3ImageComponent,
    ContainerSelectComponent,
    MeterReadingWidgetComponent,
    MatAutocompleteModule,
    SelectEntityWidgetComponent,
  ],
  declarations: [
    ConfirmationDialogComponent,
    ExtractKeysFromEnumPipe,
    BookedTimePipe,
    ImageDropComponent,
    S3ImageComponent,
    ContainerSelectComponent,
    MeterReadingWidgetComponent,
    SelectEntityWidgetComponent,
  ],
  providers: [
    AuthService,
    AdminGuard,
    StripePaymentService,
    BraintreePaymentService,
    BillomatService,
  ],
  entryComponents: [ConfirmationDialogComponent, MatSpinner],
})
export class SharedModule {}

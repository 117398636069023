import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ChancelStripeSubscriptionMutation extends Mutation {
  document = gql`
    mutation chancelStripeSubscription($subscriptionId: String!) {
      chancelStripeSubscription(subscriptionId: $subscriptionId)
    }
  `;
}

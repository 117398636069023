import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserRoleEnum } from '../../../../../../../common/enums/user-role.enum';
import { User } from '../../../../../../../common/interfaces/prisma.binding';
import { SideNavMenuEntryInterface } from '../../../../../../../common/interfaces/side-nav-menu-entry.interface';
import { AuthState } from '../../../shared/state/auth.state';

@Component({
  selector: 'tt-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss'],
})
export class SideNavMenuComponent implements OnInit {
  @Select(AuthState) authState$: Observable<User>;
  showSidebar = false;
  showAdminBar = false;

  userEntries: SideNavMenuEntryInterface[] = [
    {
      name: 'Meine Werkstatt',
      icon: 'build',
      link: 'my-workshop',
    },
    // {
    //   name: 'Projekte',
    //   icon: 'project-diagram',
    //   link: '',
    // },
    {
      name: 'Rechnungen',
      icon: 'account_balance',
      link: 'invoices',
    },
    {
      name: 'Mein Account',
      icon: 'account_circle',
      link: 'my-account',
    },
    {
      name: 'Zeiterfassung',
      icon: 'access_time',
      link: 'time-tracking',
    },
    {
      name: 'Projekte',
      icon: 'assignment',
      link: 'projects',
    },
    // {
    //   name: 'Zahlungen',
    //   icon: 'credit-card',
    //   link: 'payments',
    // },
    // {
    //   name: 'Auswertungen',
    //   icon: 'chart-bar',
    //   link: '',
    // },
  ];
  adminEntries: SideNavMenuEntryInterface[] = [
    {
      name: 'Benutzer',
      icon: 'account_circle',
      link: 'admin/user',
    },
    {
      name: 'Container',
      icon: 'store',
      link: 'admin/container',
    },
    {
      name: 'Freiflächen',
      icon: 'view_array',
      link: 'admin/area',
    },
    {
      name: 'Terminals',
      icon: 'settings_cell',
      link: 'admin/terminal',
    },
    {
      name: 'Energiezähler',
      icon: 'settings_input_hdmi',
      link: 'admin/electric-meters',
    },
    {
      name: 'GPIO Devices',
      icon: 'settings_input_svideo',
      link: 'admin/gpio-devices',
    },
    {
      name: 'Relais',
      icon: 'settings_power',
      link: 'admin/relays',
    },
    {
      name: 'Teams',
      icon: 'face',
      link: 'admin/teams',
    },
    {
      name: 'Barcodes',
      icon: 'qr_code',
      link: 'admin/barcodes',
    },
    {
      name: 'Barcode Items',
      icon: 'qr_code',
      link: 'admin/barcode_items',
    },
    {
      name: 'Zeiteinträge',
      icon: 'access_time',
      link: 'time-tracking',
    },
  ];

  constructor() {
    // sort menu entries by name
    this.adminEntries.sort(this._sortMenuEntries);
    this.userEntries.sort(this._sortMenuEntries);
    this.authState$.subscribe((authData: User) => {
      this.showSidebar = !this._isObjectEmpty(authData);

      if (authData.hasOwnProperty('userRoles')) {
        const hasAdminRole = () =>
          authData.userRoles.some((role) => role.name === UserRoleEnum.ADMIN);
        this.showAdminBar = hasAdminRole();
      }
    });
  }

  ngOnInit() {}

  private _sortMenuEntries(
    a: SideNavMenuEntryInterface,
    b: SideNavMenuEntryInterface,
  ) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // namen müssen gleich sein
    return 0;
  }

  private _isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}

import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteTimeEntryMutation extends Mutation {
  document = gql`
    mutation deleteTimeEntry($id: ID) {
      deleteTimeEntry(where: { id: $id }) {
        id
      }
    }
  `;
}

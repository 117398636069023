import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TimeTrackingComponent } from './components/time-tracking/time-tracking.component';

@NgModule({
  declarations: [TimeTrackingComponent],
  exports: [],
  imports: [CommonModule, SharedModule],
  entryComponents: [],
})
export class TimeTrackingModule {}

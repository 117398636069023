import {Action, Selector, State, StateContext} from '@ngxs/store';
import {WorkshopDataInterface} from '../../../../../../common/interfaces/workshop-data.interface';
import {AllContainerQuery} from '../../admin/container/queries/all-container.query';
import {FetchUserWorkshopDataAction} from './actions/fetch-user-workshop-data.action';
import {GetUserWorkshopDataQuery} from './queries/get-user-workshop-data.query';
import { Injectable } from '@angular/core';

export interface MyWorkshopStateModel {
  workShopMeterData: [];
}

@State<MyWorkshopStateModel>({
  name: 'myWorkshop',
  defaults: {
    workShopMeterData: [],
  },
})
@Injectable()
export class MyWorkshopState {
  constructor(private allContainerQuery: AllContainerQuery,
              private getUserWorkshopDataQuery: GetUserWorkshopDataQuery) {
  }

  @Selector()
  static workShopMeterData(state: MyWorkshopStateModel): Map<number, WorkshopDataInterface> {
    return new Map<number, WorkshopDataInterface>(state.workShopMeterData);
  }

  @Action(FetchUserWorkshopDataAction)
  async getContainers({patchState}: StateContext<MyWorkshopStateModel>, action: FetchUserWorkshopDataAction) {
    return new Promise(async (resolve, reject) => {
        this.getUserWorkshopDataQuery.watch({}, {
          fetchPolicy: 'network-only',
        }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => {
          resolve(patchState({workShopMeterData: JSON.parse(data.getMyWorkshopData)}),
          );
        });
      },
    );
  }
}

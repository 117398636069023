import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  Area,
  Container,
  ElectricMeter,
  MeterReading,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { WorkshopDataInterface } from '../../../../../../../../common/interfaces/workshop-data.interface';
import { FetchUserAreasAction } from '../../../../admin/area/actions/fetch-user-areas.action';
import { AreaState } from '../../../../admin/area/area.state';
import { FetchUserContainerByKcidAction } from '../../../../admin/container/actions/fetch-user-container-by-kcid.action';
import { ContainerState } from '../../../../admin/container/container.state';
import { FetchMeterReadingAction } from '../../../../admin/electric-meters/actions/fetch-meter-reading.action';
import { ElectricMeterState } from '../../../../admin/electric-meters/electric-meter.state';
import { ToolTimeSettingsState } from '../../../../admin/tool-time-settings/tool-time-settings.state';
import { AuthStateModel } from '../../../../shared/models/auth-state.model';
import { AuthState } from '../../../../shared/state/auth.state';
import { FetchUserWorkshopDataAction } from '../../actions/fetch-user-workshop-data.action';
import { MyWorkshopState } from '../../my-workshop.state';

export interface OverallConsumption {
  systemPowerNow: number;
  consumptionToday: number;
  consumptionYesterday: number;
  consumptionThisMonth: number;
  consumptionLastMonth: number;
  consumptionOverall: number;
}

@Component({
  selector: 'tt-my-workshop',
  templateUrl: './my-workshop.component.html',
  styleUrls: ['./my-workshop.component.scss'],
})
export class MyWorkshopComponent implements OnInit, AfterViewChecked {

  @Select(ElectricMeterState.meterReadings) meterReadings$: Observable<MeterReading[]>;
  @Select(MyWorkshopState.workShopMeterData) workShopMeterData$: Observable<Map<number, WorkshopDataInterface>>;
  @Select(ToolTimeSettingsState.kwhPrice) kwhPrice$: Observable<number>;
  @Select(AreaState.userAreas) userAreas$: Observable<Area[]>;
  @Select(ContainerState.userContainer) userContainer$: Observable<Container[]>;

  electricMeters: ElectricMeter[] = [];
  meterReadings: MeterReading[] = [];
  currentUser: AuthStateModel;
  overAllConsumption: OverallConsumption;
  now: Date;
  yesterday: Date;
  lastMonth: Date;
  moveInDate: Date;
  initiallyCreatedDate = false;

  constructor(private readonly store: Store,
              private cdRef: ChangeDetectorRef) {
  }

  async ngOnInit() {

    this.currentUser = this.store.selectSnapshot(AuthState.userDetails);
    // this.getMeterReadings();
    this.store.dispatch([new FetchUserContainerByKcidAction(this.currentUser.keycloakId),
      new FetchUserWorkshopDataAction(),
      new FetchUserAreasAction(),
      new FetchMeterReadingAction(this.currentUser.keycloakId)]);
    // construction the overall information
    this.workShopMeterData$.subscribe((workShopMeterData: Map<number, WorkshopDataInterface>) => {
      this.overAllConsumption = {
        systemPowerNow: 0,
        consumptionLastMonth: 0,
        consumptionOverall: 0,
        consumptionThisMonth: 0,
        consumptionToday: 0,
        consumptionYesterday: 0,
      };
      const meterDataValues = Array.from(workShopMeterData.values());
      if (meterDataValues.length > 0) {
        meterDataValues.forEach(meterDataValue => {
          this.overAllConsumption.consumptionOverall += meterDataValue.powerConsumptionOverAllMonthInKwh;
          this.overAllConsumption.consumptionYesterday += meterDataValue.powerConsumptionYesterdayInKwh;
          this.overAllConsumption.consumptionToday += meterDataValue.powerConsumptionTodayInKwh;
          this.overAllConsumption.consumptionThisMonth += meterDataValue.powerConsumptionThisMonthInKwh;
          this.overAllConsumption.consumptionLastMonth += meterDataValue.powerConsumptionLastMonthInKwh;
          this.overAllConsumption.systemPowerNow += meterDataValue.currentSystemPowerInW;
          // we look for the earliest meter reading and declare it as the move in date ;)
          if (this.moveInDate === undefined) {
            this.moveInDate = new Date(meterDataValue.moveInDate);
          } else if (new Date(meterDataValue.moveInDate).getTime() < this.moveInDate.getTime()) {
            this.moveInDate = new Date(meterDataValue.moveInDate);
          }
        });
      }
    });
    this.meterReadings$.subscribe(readings => this.meterReadings = readings);
  }

  parseNumber(number: string): number {
    return parseFloat(number);
  }

  ngAfterViewChecked(): void {
    if (!this.initiallyCreatedDate) {
      this.now = new Date();
      // @ts-ignore
      this.yesterday = new Date().setDate(new Date().getDate() - 1);
      // @ts-ignore
      this.lastMonth = new Date();
      this.lastMonth.setDate(0); // 0 will result in the last day of the previous month
      this.lastMonth.setDate(1);
      // the time is changing and needs a refresh - if not angular is crying
      this.cdRef.detectChanges();
      this.initiallyCreatedDate = true;
    }
  }

  async getWorkShopData() {
    this.initiallyCreatedDate = false;
    this.ngAfterViewChecked();
    await this.store.dispatch(new FetchUserWorkshopDataAction()).toPromise();
  }

  getMeterCommentByAddress(address: number) {
    const meterReading = this.meterReadings.find(data => data.meter.address === address.toString());
    if (meterReading) {
      return meterReading.meter.comment;
    }
    return '';
  }

  getTotalKwhByAddress(address: number) {
    const meterReading = this.meterReadings.find(data => data.meter.address === address.toString());
    if (meterReading) {
      return meterReading.meter.totalKwh;
    }
    return -1;
  }

  getMoveInDate(container: Container): Date | boolean {
    if (container.electricMeter !== null) {
      const electricMeterAddress = container.electricMeter.address;
      if (this.meterReadings !== undefined) {
        const containerMeterReading = this.meterReadings.find(meterReading => meterReading.meter.address === electricMeterAddress);
        if (containerMeterReading !== undefined) {
          return new Date(containerMeterReading.entryDate);
        } else return false;
      }
    }
    return false;
  }

  isTeamMeter(meterAddress: number): boolean {
    const currentUserTeam = this.store.selectSnapshot(AuthState.userTeam);
    const meters = currentUserTeam.meterReadings.map(meterReading => parseInt(meterReading.meter.address, 10));
    return meters.includes(meterAddress);

  }
}

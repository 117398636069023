import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PaymentProviderEnum} from '../../../../../../../../common/enums/payment-provider.enum';
import {TopUpCreditAction} from '../../actions/top-up-credit.action';
import {ChargingPaymentMethodInterface} from '../../interfaces/charging-payment-method.interface';
import {PaymentMethodInterface} from '../../interfaces/payment-method.interface';
import {PaymentState} from '../../payment.state';

@Component({
  selector: 'tt-payment-method-braintree',
  templateUrl: './payment-method-braintree.component.html',
  styleUrls: ['./payment-method-braintree.component.scss'],
})
export class PaymentMethodBraintreeComponent implements OnInit, PaymentMethodInterface {
  @Select(PaymentState.selectedTopUpPaymentMethod) selectedTopUpPaymentMethod$: Observable<ChargingPaymentMethodInterface>;
  @Select(PaymentState.customTopUpValue) customTopUpValue$: Observable<number>;
  paymentConfiguration$ = new Subject<ChargingPaymentMethodInterface>();
  disabled$ = new BehaviorSubject<boolean>(true);

  paymentMethodDisabled$ = new BehaviorSubject<boolean>(true);
  @Select(PaymentState.isBraintreePaymentMethodRequestable) isBraintreePaymentMethodRequestable$: Observable<boolean>;

  constructor(private store: Store) {
    this.isBraintreePaymentMethodRequestable$.subscribe(requestable => {
      this.paymentMethodDisabled$.next(!requestable);
    });
    this.disabled$.subscribe(disabled => {
      if (!disabled) {
        this.paymentConfiguration$.next({
          paymentProvider: PaymentProviderEnum.BRAINTREE,
        });
      }
    });
  }

  topUpCredit() {
    this.store.dispatch(new TopUpCreditAction());
  }

  ngOnInit() {
  }
}

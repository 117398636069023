import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteAreaMutation extends Mutation {
  document = gql`
      mutation deleteArea($id: ID!) {
          deleteArea(where: {id: $id}) {
              id
          }
      }
  `;
}

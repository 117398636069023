import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTimeEntryMutation extends Mutation {
  document = gql`
    mutation createTimeEntry(
      $date: DateTime!
      $project: ID
      $bookedTime: Int!
      $description: String
      $userId: ID
    ) {
      createTimeEntry(
        data: {
          date: $date
          project: { connect: { id: $project } }
          bookedTime: $bookedTime
          description: $description
          user: { connect: { id: $userId } }
        }
      ) {
        id
        project {
          id
          projectNumber
        }
        description
        date
        isApproved
        bookedTime
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}

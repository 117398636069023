import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from '../../../../../../../../common/interfaces/prisma.binding';
import {StripePaymentService} from '../../../../shared/services/stripe-payment.service';
import {AuthState} from '../../../../shared/state/auth.state';


@Component({
  selector: 'tt-iban',
  templateUrl: './iban.component.html',
  styleUrls: ['./iban.component.scss'],
})
export class IbanComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ibanElement', {static: true}) ibanElement: ElementRef;

  iban: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  bankName: string;
  fullName: string;
  @Select(AuthState) authUser$: Observable<any>;

  constructor(private cd: ChangeDetectorRef, private paymentService: StripePaymentService) {
  }

  ngOnInit() {
    this.authUser$.subscribe((user: User) => {
      this.fullName = `${user.firstName} ${user.lastName}`;
    });
  }

  ngAfterViewInit(): void {
    this.iban = this.paymentService.elements.create('iban', {
      supportedCountries: ['SEPA'],
    });

    this.iban.mount(this.ibanElement.nativeElement);

    this.iban.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy(): void {
    this.iban.removeEventListener('change', this.cardHandler);
    this.iban.destroy();
  }

  onChange({error, bankName}) {
    if (bankName) {
      this.bankName = bankName;
    }

    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }

    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const sourceData = {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name: this.fullName,
        // email: document.querySelector('input[name="email"]').value,
      },
      mandate: {
        // Automatically send a mandate notification email to your customer
        // once the source is charged.
        notification_method: 'email',
      },
    };
    const {source, error} = await this.paymentService.stripe.createSource(this.iban, sourceData);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      console.log('Success!', source);
      // this.paymentService.addCard(source);
      // ...send the token to the your backend to process the charge
    }
  }
}

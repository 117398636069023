import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { BarcodesOverviewComponent } from './components/barcodes-overview/barcodes-overview.component';
import { IconsModule } from 'angular-bootstrap-md';
import { BarcodeItemsComponent } from './components/barcode-items/barcode-items.component';

@NgModule({
  declarations: [BarcodesOverviewComponent, BarcodeItemsComponent],
  providers: [],
  imports: [CommonModule, SharedModule, IconsModule],
})
export class BarcodeModule {
}

import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Container} from '../../../../../../common/interfaces/prisma.binding';
import {GetContainerByKeycloakIdQuery} from '../../public/my-workshop/queries/get-container-by-keycloakid.query';
import {FetchContainerAction} from './actions/fetch-container.action';
import {FetchUserContainerByKcidAction} from './actions/fetch-user-container-by-kcid.action';
import {AllContainerQuery} from './queries/all-container.query';
import { Injectable } from '@angular/core';

export interface ContainerStateModel {

  container: Container[];
  userContainer: Container[];
}

@State<ContainerStateModel>({
  name: 'containers',
  defaults: {
    container: [],
    userContainer: [],
  },
})
@Injectable()
export class ContainerState {
  constructor(private allContainerQuery: AllContainerQuery,
              private userContainer: GetContainerByKeycloakIdQuery) {
  }

  @Selector()
  static containers(state: ContainerStateModel) {
    return state.container;
  }
  @Selector()
  static userContainer(state: ContainerStateModel) {
    return state.userContainer;
  }

  @Action(FetchUserContainerByKcidAction)
  async getUserContainerByKcId({patchState}: StateContext<ContainerStateModel>, action: FetchUserContainerByKcidAction) {
    return new Promise(async (resolve, reject) => {
      this.userContainer.watch({keycloakID: action.kcId}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({userContainer: data.containers})));
    });
  }

  @Action(FetchContainerAction)
  async getContainers({patchState}: StateContext<ContainerStateModel>, action: FetchContainerAction) {
    return new Promise(async (resolve, reject) => {
      this.allContainerQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({container: data.containers})));
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Container, Team, User } from '../../../../../../../../common/interfaces/prisma.binding';
import { AuthState } from '../../../../shared/state/auth.state';
import { FetchTeamsAction } from '../../actions/fetch-teams.action';
import { DeleteTeamMutation } from '../../mutations/delete-team.mutation';

import { TeamState } from '../../team.state';

@Component({
  selector: 'tt-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss'],
})
export class TeamOverviewComponent implements OnInit {
  users;
  displayedColumns: string[] = ['id', 'name', 'container', 'users', 'actions'];
  dataSource: MatTableDataSource<Team>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(TeamState.teams) teams$: Observable<Team[]>;
  private _currentUser: User;

  constructor(private store: Store,
              private readonly logger: NGXLogger,
              private readonly deleteTeamMutation: DeleteTeamMutation) {

  }

  ngOnInit() {
    this._currentUser = this.store.selectSnapshot(AuthState.userDetails) as User;

    this.store
      .dispatch(new FetchTeamsAction());
    this.teams$.subscribe(teams => {
      this.dataSource = new MatTableDataSource(teams);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  processContainer(containers: Container[]) {
    return containers.map(container => container.internalNumber).concat();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteTeam(id) {
    this.deleteTeamMutation.mutate({ id }).subscribe(({ data }) => {
      this.store
        .dispatch(new FetchTeamsAction());
    }, (error) => {
      error.graphQLErrors.map(({ message }, i) => (
        this.logger.log(message, i)
      ));
    });

  }
}

import {Routes} from '@angular/router';
import {RelaysDetailComponent} from './components/relays-detail/relays-detail.component';
import {RelaysOverviewComponent} from './components/relays-overview/relays-overview.component';

export const RELAY_ROUTES: Routes = [

  {path: 'admin/relays', component: RelaysOverviewComponent},
  {path: 'admin/relays/add', component: RelaysDetailComponent},
  {path: 'admin/relays/edit/:id', component: RelaysDetailComponent},
];

import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {User} from '../../../../../common/interfaces/prisma.binding';
import {FetchTeamsAction} from '../admin/team/actions/fetch-teams.action';
import {FetchUserAction} from '../admin/user/actions/fetch-user.action';
import {UserState} from '../admin/user/user.state';
import {AddMessageToSnackbarQueueAction} from '../shared/actions/add-message-to-snackbar-queue.action';
import {ESnackBarEnum} from '../shared/enums/e-snack-bar.enum';

@Component({
  selector: 'tt-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent implements OnInit {

  users: User[] = [];
  selectedUser = [];
  @Select(UserState.users) users$: Observable<User[]>;

  constructor(private logger: NGXLogger,
              private store: Store) {
  }

  ngOnInit() {
    this.selectedUser.push({
      'id': 'ck1jm07cz002c0887mefqspyk',
      'currency': 'EUR',
      'credit': null,
      'email': 'tomomoritz@gmail.com',
      'firstName': 'Single',
      'lastName': 'User',
      'keycloakID': '440df0c9-5bfd-48cc-bb14-763bb073a932',
      'username': 'singleuser',
      '__typename': 'User',
    });
    this.selectedUser.push({
      'id': 'ck1jm07c800260887bh6teg4c',
      'currency': 'EUR',
      'credit': 2000,
      'email': 'thomas@tooltime.berlin',
      'firstName': 'Thomas',
      'invoices': [],
      'keycloakID': '8f54d36d-13a4-4d13-96d3-5c15fd8a3627',
      'lastName': 'Moritz',
      'city': 'Langebrück',
      'street': 'Jakob-Weinheimer-Str.',
      'zip': '01465',
      'housenumber': '11',
      'meterMeterReading': [],
      'birthday': null,
      'username': 'tomo',
      'stripeID': 'cus_El9B7d414D7utZ',
      'userRoles': [
        {
          'name': 'ADMIN',
          '__typename': 'UserRole',
        },
      ],
      '__typename': 'User',
    });
    this.store.dispatch([
      new FetchUserAction(),
      new FetchTeamsAction()]).subscribe(() => {
      this.users = this.store.selectSnapshot(UserState.users);

    });

  }

  openErrorSnackbar() {
    this.store.dispatch(new AddMessageToSnackbarQueueAction({
      title: 'GQ Error',
      message: 'Fiese Nachricht',
      snackbarType: ESnackBarEnum.ERROR_SNACKBAR,
    }));
  }

  openInfoSnackbar() {
    this.store.dispatch(new AddMessageToSnackbarQueueAction({
      title: 'Information',
      message: 'Eine wichtige Nachricht',
      snackbarType: ESnackBarEnum.INFO_SNACKBAR,
    }));
  }

  onSelectionChange(event) {
    console.log('Kuh', event);
  }
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ConfirmStripePaymentIntentMutation extends Mutation {
  document = gql`
    mutation confirmStripePaymentIntent($paymentIntentId: String!) {
      confirmStripePaymentIntent(paymentIntentId: $paymentIntentId) {
        status
      }
    }
  `;
}

import { Routes } from '@angular/router';
import { AdminGuard } from '../../shared/guards/admin.guard';
import { TimeEntryManagementComponent } from './components/time-entry-management/time-entry-management.component';

export const PROJECT_ADMIN_ROUTES: Routes = [
  {
    path: 'admin/time-entry-management',
    component: TimeEntryManagementComponent,
    canActivate: [AdminGuard],
  },
];

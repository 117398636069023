import {Injectable} from '@angular/core';
import {Subscription} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class  UpdateUserSubscription extends Subscription {
  document = gql`
    subscription user($id:ID!)  {
      user(where: {
        mutation_in: [UPDATED], node: {id:$id}
      }) {
        node {
          credit
        }
      }
    }
  `;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Subscription } from 'apollo-client/util/Observable';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Terminal } from '@tooltime/server/src/modules/prisma/prisma-client';
import { TerminalState } from '../../terminal.state';
import { AllTerminalQuery } from '../../queries/all-terminal.query';
import { DeleteTerminalMutation } from '../../mutations/delete-terminal.mutation';
import { FetchTerminalAction } from '../../actions/fetch-terminal.action';

@Component({
  selector: 'tt-terminal-overview',
  templateUrl: './terminal-overview.component.html',
  styleUrls: ['./terminal-overview.component.scss'],
})
export class TerminalOverviewComponent implements OnInit {
  terminal: Subscription;
  displayedColumns: string[] = ['name', 'type', 'actions'];
  dataSource: MatTableDataSource<Terminal>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(TerminalState.terminals) terminals$: Observable<Terminal[]>;

  constructor(private allTerminalQuery: AllTerminalQuery,
              private logger: NGXLogger,
              private store: Store,
              private deleteTerminalMutation: DeleteTerminalMutation,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.store
      .dispatch(new FetchTerminalAction());
    this.terminals$.subscribe(terminals => {
      this.dataSource = new MatTableDataSource(terminals);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteTerminal(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Dieses Terminal wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTerminalMutation.mutate({ id }).subscribe(({ data }) => {
          this.logger.log('got terminal delete data', data);
          this.store
            .dispatch(new FetchTerminalAction());
        }, (error) => {
          error.graphQLErrors.map(({ message }, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });

  }

  getFloat(number: string) {
    return parseFloat(number);
  }
}

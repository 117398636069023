import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';

import gql from 'graphql-tag';
import {Payment} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  stripeInvoices: Payment[];
}

@Injectable({
  providedIn: 'root',
})
export class GetUserTopUpPaymentsQuery extends Query<Response> {
  document = gql`
    query payments {
      payments {
        id
        amount
        createdAt
        currency
        paymentID
        receiptUrl
      }
    }
  `;
}

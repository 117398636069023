import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { TopUpCreditSucceededAction } from '../../public/payments/actions/top-up-credit-succeeded.action';
import { CreateCreditCardMutation } from '../../public/payments/mutations/add-card.mutation';
import { CreateStripePaymentIntentMutation } from '../../public/payments/mutations/add-payment.mutation';
import { ChancelStripeSubscriptionMutation } from '../../public/payments/mutations/chancel-stripe-subscription.mutation';
import { ConfirmStripePaymentIntentMutation } from '../../public/payments/mutations/confirm-stripe-payment-intent.mutation';
import { CreateStripeSubscriptionMutation } from '../../public/payments/mutations/create-stripe-subscription.mutation';
import { DecreaseHttpCallCount } from '../actions/decrease-http-call-count.action';
import { IncreaseHttpCallCount } from '../actions/increase-http-call-count.action';
import { GetStripeUserByContextQuery } from '../queries/get-stripe-user-by-context.query';

@Injectable()
export class StripePaymentService {
  // ToDo: Put stripe ID to config
  // @ts-ignore
  stripe = Stripe('pk_test_S5kPbFvlaeg8fhutGjZG86Nn'); // use your test publishable key
  elements = this.stripe.elements();

  constructor(
    private addCardMutation: CreateCreditCardMutation,
    private createStripeSubscriptionMutation: CreateStripeSubscriptionMutation,
    private chancelStripeSubscriptionMutation: ChancelStripeSubscriptionMutation,
    private getStripeUserByContextQuery: GetStripeUserByContextQuery,
    private createStripePaymentIntentMutation: CreateStripePaymentIntentMutation,
    private confirmStripePaymentIntentMutation: ConfirmStripePaymentIntentMutation,
    private store: Store,
  ) {}

  addCard(source) {
    this.addCardMutation
      .mutate({
        source: JSON.stringify(source),
      })
      .subscribe(
        ({ data }) => {
          console.log('Add card', data);
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // ToDO: Proper Error Handling
            console.log(message, i),
          );
        },
      );
  }

  chancelStripeSubscription(subscriptionId: string) {
    this.chancelStripeSubscriptionMutation
      .mutate({
        subscriptionId: subscriptionId,
      })
      .subscribe(
        ({ data }) => {
          console.log('Chancel Subscription');
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // ToDO: Proper Error Handling
            console.log(message, i),
          );
        },
      );
  }

  createStripeSubscription(planId: string) {
    this.createStripeSubscriptionMutation
      .mutate({
        planId: planId,
      })
      .subscribe(
        ({ data }) => {
          console.log('Add Subscription', data);
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // ToDO: Proper Error Handling
            console.log(message, i),
          );
        },
      );
  }

  /**
   * Huge monster method that somehow has to be refactored
   * now it handles the whole stripe top up process
   * @param paymentIntent
   */
  createStripePaymentIntent(paymentIntent) {
    this.createStripePaymentIntentMutation
      .mutate({
        amount: paymentIntent.amount,
        confirm: paymentIntent.confirm,
        currency: paymentIntent.currency,
        source: paymentIntent.source,
        description: paymentIntent.description,
      })
      .subscribe(
        async ({ data }) => {
          if (data['createStripePaymentIntent'].status === 'succeeded') {
            this._topUpSuccess(data['createStripePaymentIntent']);
          } else {
            this.store.dispatch(new IncreaseHttpCallCount()); // trigger loading indicator
            // we handle 3D secure card confirmation with stripe elements SDL
            const {
              error: errorAction,
              paymentIntent: paymentIntentReturn,
            } = await this.stripe.handleCardPayment(
              data['createStripePaymentIntent'].client_secret,
            );
            this.store.dispatch(new DecreaseHttpCallCount()); // destroy loading indicator
            if (
              errorAction === undefined &&
              paymentIntentReturn.status !== 'succeeded'
            ) {
              this.confirmStripePaymentIntent(paymentIntentReturn.id);
            } else if (paymentIntentReturn.status === 'succeeded') {
              this._topUpSuccess(paymentIntentReturn);
            } else {
              console.log('Payment Top UP error');
            }
          }
        },
        error => {
          error.graphQLErrors.map(({ message }, i) => console.log(message, i));
        },
      );
  }

  confirmStripePaymentIntent(paymentIntentId: string) {
    this.confirmStripePaymentIntentMutation
      .mutate({
        paymentIntentId,
      })
      .subscribe(
        ({ data }) => {
          console.log('Confirm', data);
          if (data['paymentIntent'].status === 'succeeded') {
            this._topUpSuccess(data['paymentIntent']);
          }
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // ToDO: Proper Error Handling
            console.log(message, i),
          );
        },
      );
  }

  getStripeUserByContext(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getStripeUserByContextQuery
        .watch({
          fetchPolicy: 'network-only',
        })
        .valueChanges.subscribe(userData =>
          resolve(userData.data.stripeUserByContext),
        );
    });
  }

  private _topUpSuccess(paymentIntent: any) {
    this.store.dispatch(new TopUpCreditSucceededAction(paymentIntent));
  }
}

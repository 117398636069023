import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateElectricMeterMutation extends Mutation {
  document = gql`
      mutation updateElectricMeter($id: ID!,$address: String,$brandId:ID,$type: MeterType,$comment:String) {
          updateElectricMeter(
              where: { id: $id }
              data: {
                  address:$address
                  type:$type,
                  comment:$comment
                  brand: {
                      connect: {id: $brandId}
                  }
              }) {id}
      }
  `;
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { TerminalOverviewComponent } from './components/terminal-overview/terminal-overview.component';
import { TerminalDetailComponent } from './components/terminal-detail/terminal-detail.component';

@NgModule({
  declarations: [TerminalOverviewComponent, TerminalDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class TerminalModule {
}

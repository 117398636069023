import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Project } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allProjects: Project[];
}

@Injectable({
  providedIn: 'root',
})
export class AllProjectsQuery extends Query<Response> {
  document = gql`
    query allProjects {
      projects {
        id
        createdAt
        isGlobal
        projectNumber
        projectDescription
        user {
          id
        }
        team {
          id
        }
      }
    }
  `;
}

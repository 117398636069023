import {Routes} from '@angular/router';
import {ElectricMetersDetailComponent} from './components/electric-meter-detail/electric-meters-detail.component';
import {ElectricMetersOverviewComponent} from './components/electric-meters-overview/electric-meters-overview.component';


export const ELECTRIC_METERS_ROUTES: Routes = [

  {path: 'admin/electric-meters', component: ElectricMetersOverviewComponent},
  {path: 'admin/electric-meters/add', component: ElectricMetersDetailComponent},
  {path: 'admin/electric-meters/edit/:id', component: ElectricMetersDetailComponent},
];

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateAreaMutation extends Mutation {
  document = gql`
      mutation updateArea($id:ID!, $description: String!, $pricePerSquareMeter:Float!,$squareMeter:Float!, $user: UserUpdateOneInput, $team: TeamUpdateOneInput,$meter: ElectricMeterUpdateOneInput) {
          updateArea(where:{
              id:$id
          }
              data: {
                  description:$description
                  pricePerSquareMeter:$pricePerSquareMeter
                  squareMeter:$squareMeter
                  meter:$meter
                  user: $user
                  team: $team
              }
          ) {
              id
              description
          }
      }
  `;
}

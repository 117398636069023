import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {PaymentMethodPayload} from 'braintree-web-drop-in';
import {PaymentProviderEnum} from '../../../../../../common/enums/payment-provider.enum';
import {UpdateBraintreeDropInAction} from '../../shared/actions/update-braintree-drop-in.action';
import {BraintreePaymentService} from '../../shared/services/braintree-payment.service';
import {StripePaymentService} from '../../shared/services/stripe-payment.service';
import {AuthState} from '../../shared/state/auth.state';
import {FetchAbstractSubscriptionsAction} from './actions/fetch-abstract-subscriptions.action';
import {FetchStripePlansAction} from './actions/fetch-stripe-plans.action';
import {FetchUserPaymentMethodsAction} from './actions/fetch-user-payment-methods.action';
import {FetchUserSubscriptionInvoicesAction} from './actions/fetch-user-subscription-invoices.action';
import {FetchUserTopUpPaymentsAction} from './actions/fetch-user-top-up-payments.action';
import {SetCustomTopUpValueAction} from './actions/set-custom-top-up-value.action';
import {SetIsBraintreePaymentMethodRequestableAction} from './actions/set-is-braintree-payment-method-requestable.action';
import {SetSubscriptionPaymentMethodAction} from './actions/set-subscription-payment-method.action';
import {SetTeamSubscriptionPaymentMethodAction} from './actions/set-team-subscription-payment-method.action';
import {SetTopUpPaymentMethodAction} from './actions/set-top-up-payment-method.action';
import {TopUpCreditSucceededAction} from './actions/top-up-credit-succeeded.action';
import {TopUpCreditAction} from './actions/top-up-credit.action';
import {CreateBraintreePaymentMutation} from './mutations/create-braintree-payment.mutation';
import {PaymentStateModel} from './payment-state.model';
import {AbstractSubscriptionsQuery} from './queries/get-abstract-subscriptions.query';
import {StripePlansQuery} from './queries/get-stripe-plans.query';
import {GetStripeSubscriptionInvoicesQuery} from './queries/get-stripe-subscription-invoices.query';
import {GetUserPaymentMethodsQuery} from './queries/get-user-payment-methods.query';
import {GetUserTopUpPaymentsQuery} from './queries/get-user-top-up-payments.query';
import { Injectable } from '@angular/core';


@State<PaymentStateModel>({
  name: 'payment', // the name of our state
  defaults: {
    abstractSubscriptions: [],
    customTopUpValue: 0,
    isBraintreePaymentMethodRequestable: false,
    invoices: [],
    topUpPayments: [],
    stripePlans: [],
    selectedTopUpPaymentMethod: {},
    selectedSubscriptionPaymentMethod: {},
    selectedTeamSubscriptionPaymentMethod: {},
    topUpValues: [20, 40, 60],
    userPaymentMethods: [],
  },
})
@Injectable()
export class PaymentState {

  constructor(private abstractSubscriptionsQuery: AbstractSubscriptionsQuery,
              private braintreePaymentService: BraintreePaymentService,
              private stripePlansQuery: StripePlansQuery,
              private userPaymentsQuery: GetUserPaymentMethodsQuery,
              private stripePaymentService: StripePaymentService,
              private subscriptionInvoicesQuery: GetStripeSubscriptionInvoicesQuery,
              private userTopUpPpaymentQuery: GetUserTopUpPaymentsQuery,
              private store: Store,
              private createBraintreePaymentMutation: CreateBraintreePaymentMutation) {
  }

  @Selector()
  static stripePlans(state: PaymentStateModel) {
    return state.stripePlans;
  }

  @Selector()
  static subscriptionInvoices(state: PaymentStateModel) {
    return state.invoices;
  }

  @Selector()
  static topUpPayments(state: PaymentStateModel) {
    return state.topUpPayments;
  }

  @Selector()
  static abstractSubscriptions(state: PaymentStateModel) {
    return state.abstractSubscriptions;
  }

  @Selector()
  static topUpValues(state: PaymentStateModel) {
    return state.topUpValues;
  }

  @Selector()
  static paymentMethods(state: PaymentStateModel) {
    return state.userPaymentMethods;
  }

  @Selector()
  static customTopUpValue(state: PaymentStateModel) {
    return state.customTopUpValue;
  }

  @Selector()
  static selectedTopUpPaymentMethod(state: PaymentStateModel) {
    return state.selectedTopUpPaymentMethod;
  }

  @Selector()
  static selectedSubscriptionPaymentMethod(state: PaymentStateModel) {
    return state.selectedSubscriptionPaymentMethod;
  }

  @Selector()
  static selectedTeamSubscriptionPaymentMethod(state: PaymentStateModel) {
    return state.selectedTeamSubscriptionPaymentMethod;
  }

  @Selector()
  static isBraintreePaymentMethodRequestable(state: PaymentStateModel) {
    return state.isBraintreePaymentMethodRequestable;
  }

  @Action(FetchAbstractSubscriptionsAction)
  async getabstractSubscriptions({patchState}: StateContext<PaymentStateModel>, action: FetchAbstractSubscriptionsAction) {
    return new Promise(async (resolve, reject) => {
      this.abstractSubscriptionsQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({abstractSubscriptions: data.abstractSubscriptions})));
    });
  }

  @Action(FetchStripePlansAction)
  async getStripePlans({patchState}: StateContext<PaymentStateModel>, action: FetchStripePlansAction) {
    return new Promise(async (resolve, reject) => {
      this.stripePlansQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({stripePlans: data.stripePlans})));
    });
  }

  @Action(FetchUserPaymentMethodsAction)
  async getUserPaymentMethods({patchState}: StateContext<PaymentStateModel>, action: FetchUserPaymentMethodsAction) {
    return new Promise(async (resolve, reject) => {
      this.userPaymentsQuery.watch({id: action.userId}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({userPaymentMethods: data.paymentMethods})));
    });
  }

  @Action(FetchUserSubscriptionInvoicesAction)
  async getUserSubscriptionInvoices({patchState}: StateContext<PaymentStateModel>, action: FetchUserSubscriptionInvoicesAction) {
    return new Promise(async (resolve, reject) => {
      this.subscriptionInvoicesQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({invoices: data.subscriptionInvoices})));
    });
  }

  @Action(FetchUserTopUpPaymentsAction)
  async getUserTopUpPayments({patchState}: StateContext<PaymentStateModel>) {
    return new Promise(async (resolve, reject) => {
      this.userTopUpPpaymentQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({topUpPayments: data.payments})));
    });
  }

  @Action(SetCustomTopUpValueAction)
  setCustomTopUpValue({patchState}: StateContext<PaymentStateModel>, action: SetCustomTopUpValueAction) {
    patchState({customTopUpValue: action.customToUpValue * 100});
  }

  @Action(SetTopUpPaymentMethodAction)
  setTopUpPaymentMethod({patchState}: StateContext<PaymentStateModel>, action: SetTopUpPaymentMethodAction) {
    patchState({selectedTopUpPaymentMethod: action.paymentMethod});
  }

  @Action(SetSubscriptionPaymentMethodAction)
  setSubscriptionPaymentMethod({patchState}: StateContext<PaymentStateModel>, action: SetSubscriptionPaymentMethodAction) {
    patchState({selectedSubscriptionPaymentMethod: action.paymentMethod});
  }

  @Action(SetTeamSubscriptionPaymentMethodAction)
  setTeamSubscriptionPaymentMethod({patchState}: StateContext<PaymentStateModel>, action: SetTeamSubscriptionPaymentMethodAction) {
    patchState({selectedTeamSubscriptionPaymentMethod: action.paymentMethod});
  }

  @Action(TopUpCreditAction)
  async topUpCreditAction(ctx: StateContext<PaymentStateModel>, action: TopUpCreditAction) {
    const state = ctx.getState();
    const currency = this.store.selectSnapshot(AuthState.userCurrency);
    switch (state.selectedTopUpPaymentMethod.paymentProvider) {
      case PaymentProviderEnum.CREDIT_CARD:
        this.stripePaymentService.createStripePaymentIntent({
          amount: state.customTopUpValue,
          confirm: true,
          currency,
          description: 'Tool Time Top Up',
          source: state.selectedTopUpPaymentMethod.stripePaymentSource,
        });
        break;
      case PaymentProviderEnum.BRAINTREE:
        const instance = this.braintreePaymentService.braintreeInstance;
        const paymentMethodPayload = await instance.requestPaymentMethod().catch(err => {
          console.log('Braintree Payment Method error');
        });
        this.createBraintreePaymentMutation
          .mutate({
            amount: (state.customTopUpValue / 100).toString(),
            currency: 'EUR',
            nonce: (paymentMethodPayload as PaymentMethodPayload).nonce,
          })
          .subscribe(({data}) => {
            instance.teardown().then(() => {
              this.store.dispatch(new SetIsBraintreePaymentMethodRequestableAction(false));
              this.store.dispatch(new UpdateBraintreeDropInAction(true));
            });
          }, (error) => {
            error.graphQLErrors.map(({message}, i) => (
              // ToDO: Proper Error Handling
              console.log(message, i)
            ));
          });

        break;
    }

  }

  @Action(TopUpCreditSucceededAction)
  topUpCreditSucceededAction(ctx: StateContext<PaymentStateModel>, action: TopUpCreditSucceededAction) {
    console.log('Succeded action', action.paymentIntent);
  }

  @Action(SetIsBraintreePaymentMethodRequestableAction)
  setBraintreePaymentMethodRequestableAction({patchState}: StateContext<PaymentStateModel>, action: SetIsBraintreePaymentMethodRequestableAction) {
    patchState({isBraintreePaymentMethodRequestable: action.isPaymentMethodRequestable});
  }
}

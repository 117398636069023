import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateProjectMutation extends Mutation {
  document = gql`
    mutation createProject(
      $projectNumber: String!
      $isGlobal: Boolean
      $projectDescription: String
      $userId: ID
    ) {
      createProject(
        data: {
          isGlobal: $isGlobal
          projectNumber: $projectNumber
          projectDescription: $projectDescription
          user: { connect: { id: $userId } }
        }
      ) {
        id
        projectDescription
        projectNumber
        isGlobal
        user {
          id
          username
          firstName
          lastName
        }
      }
    }
  `;
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AREA_ROUTES } from './modules/admin/area/area.routes';
import { CONTAINER_ROUTES } from './modules/admin/container/container.routes';
import { ELECTRIC_METERS_ROUTES } from './modules/admin/electric-meters/electric-meters.routes';
import { GPIO_DEVICES_ROUTES } from './modules/admin/gpio-devices/gpio-devicess.routes';
import { RELAY_ROUTES } from './modules/admin/relays/relayss.routes';
import { TEAM_ROUTES } from './modules/admin/team/team.routes';
import { USER_ROUTES } from './modules/admin/user/user.routes';
import { INVOICES_ROUTES } from './modules/public/invoices/invoices.routes';
import { MY_WORKSHOP_ROUTES } from './modules/public/my-workshop/my-workshop.routes';
import { PAYMENTS_ROUTES } from './modules/public/payments/payments.routes';
import { HomeComponent } from './modules/root/components/home/home.component';
import { SandboxComponent } from './modules/sandbox/sandbox.component';
import { TERMINAL_ROUTES } from './modules/admin/terminal/terminal.routes';
import { MY_ACCOUNT_ROUTES } from './modules/public/my-account/my-account.routes';
import { BARCODE_ROUTES } from './modules/admin/barcode/barcode.routes';
import { TIME_TRACKING_ROUTES } from './modules/public/time-tracking/time-tracking.routes';
import { PROJECT_ADMIN_ROUTES } from './modules/admin/project-admin/project-admin.routes';
import { PROJECTS_ROUTES } from './modules/public/projects/projects.routes';

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'home',
        },
        { path: 'home', component: HomeComponent },
        { path: 'sandbox', component: SandboxComponent },
        ...USER_ROUTES,
        ...ELECTRIC_METERS_ROUTES,
        ...PAYMENTS_ROUTES,
        ...TEAM_ROUTES,
        ...CONTAINER_ROUTES,
        ...INVOICES_ROUTES,
        ...MY_WORKSHOP_ROUTES,
        ...GPIO_DEVICES_ROUTES,
        ...RELAY_ROUTES,
        ...AREA_ROUTES,
        ...TERMINAL_ROUTES,
        ...MY_ACCOUNT_ROUTES,
        ...BARCODE_ROUTES,
        ...TIME_TRACKING_ROUTES,
        ...PROJECT_ADMIN_ROUTES,
        ...PROJECTS_ROUTES,
      ],
      { relativeLinkResolution: 'legacy' },
    ),
  ],
})
export class AppRoutingModule {}

import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UpdateBraintreeDropInAction } from '../../../../shared/actions/update-braintree-drop-in.action';
import { BraintreePaymentService } from '../../../../shared/services/braintree-payment.service';
import { AuthState } from '../../../../shared/state/auth.state';
import { UiState } from '../../../../shared/state/ui.state';
import { SetIsBraintreePaymentMethodRequestableAction } from '../../actions/set-is-braintree-payment-method-requestable.action';
import { PaymentState } from '../../payment.state';

@Component({
  selector: 'tt-paypal-button-drop-in',
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: './paypal-button-drop-in.component.html',
  styleUrls: ['./paypal-button-drop-in.component.scss'],
})
export class PaypalButtonDropInComponent
  implements AfterViewInit, OnInit, OnChanges {
  @Select(PaymentState.isBraintreePaymentMethodRequestable)
  isBraintreePaymentMethodRequestable: Observable<boolean>;
  @Select(UiState.updateBraintreeDropIn)
  updateBraintreeDropIn$: Observable<boolean>;
  braintreeClientToken: string;

  constructor(
    private store: Store,
    private braintreePaymenService: BraintreePaymentService,
  ) {
    this.braintreeClientToken = this.store.selectSnapshot(
      AuthState.braintreeClientToken,
    );
  }

  ngOnInit(): void {
    this.updateBraintreeDropIn$.subscribe((updateBraintreeDropInState) => {
      if (updateBraintreeDropInState) {
        this.startDropin();
        this.store.dispatch(new UpdateBraintreeDropInAction(false));
      }
    });
  }

  async startDropin() {
    let instance;
    // @ts-ignore
    instance = await dropin
      .create({
        authorization: this.braintreeClientToken,
        container: '#dropin-container',
        card: false,
        locale: 'de_DE',
        paypal: {
          flow: 'vault',
        },
      })
      .catch((err) => console.log('Braintree dropin error', err));

    if (instance.isPaymentMethodRequestable()) {
      this.braintreePaymenService.braintreeInstance = instance;
      this.store.dispatch(
        new SetIsBraintreePaymentMethodRequestableAction(
          instance.isPaymentMethodRequestable(),
        ),
      );
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.startDropin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHHHHHAAANGE!!!');
  }
}

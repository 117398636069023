import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface Response {
  getFirstMeterReadingFromDate: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReadingOnDateQuery extends Query<Response> {
  document = gql`
      query getFirstMeterReadingFromDate($date: String!, $meterAddress:Int!) {
          getFirstMeterReadingFromDate(date:$date, meterAddress:$meterAddress)
      }
  `;
}

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';

import gql from 'graphql-tag';
import {StripeInvoice} from '../../../../../../../common/interfaces/stripe.bindings';

export interface Response {
  stripeInvoices: StripeInvoice[];
}

@Injectable({
  providedIn: 'root',
})
export class GetStripeSubscriptionInvoicesQuery extends Query<Response> {
  document = gql`
    query subscriptionInvoices {
      subscriptionInvoices {
        id
        lines
        invoice_pdf
        number
      }
    }
  `;
}

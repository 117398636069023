import { Injectable } from '@angular/core';

import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UiState } from '../state/ui.state';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  @Select(UiState.showSpinner) showSpinner$: Observable<any>;
  private spinnerTopRef = this.cdkSpinnerCreate();


  constructor(
    private overlay: Overlay,
  ) {
    this.showSpinner$.subscribe(showSpinner => {
      if (showSpinner) {
        this.showSpinner();
      } else {
        this.stopSpinner();
      }
    });
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  private showSpinner() {
    this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
  }

  private stopSpinner() {
    this.spinnerTopRef.detach();
  }
}

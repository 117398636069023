import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Team} from '../../../../../../common/interfaces/prisma.binding';
import {AuthService} from '../../shared/services/auth.service';
import {FetchTeamsAction} from './actions/fetch-teams.action';
import {AllTeamsQuery} from './queries/all-teams.query';
import { Injectable } from '@angular/core';

export interface TeamStateModel {
  teams: Team[];
}

@State<TeamStateModel>({
  name: 'team',
  defaults: {
    teams: [],
  },
})
@Injectable()
export class TeamState {
  constructor(private allTeamsQuery: AllTeamsQuery, private authServie: AuthService) {
  }

  @Selector()
  static teams(state: TeamStateModel) {
    return state.teams;
  }

  @Action(FetchTeamsAction)
  async getTeams({patchState}: StateContext<TeamStateModel>, action: FetchTeamsAction) {
    return new Promise(async (resolve, reject) => {
      this.allTeamsQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({data, loading}: { data: any, loading: boolean }) => resolve(patchState({teams: data.teams})));
    });
  }
}

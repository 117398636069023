import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Container } from '../../../../../../../common/interfaces/prisma.binding';
import { FetchContainerAction } from '../../../admin/container/actions/fetch-container.action';
import { ContainerState } from '../../../admin/container/container.state';
import { CreateContainerMutation } from '../../../admin/container/mutations/create-container-mutation';

@Component({
  selector: 'tt-container-select',
  templateUrl: './container-select.component.html',
  styleUrls: ['./container-select.component.scss'],
})

export class ContainerSelectComponent implements OnInit {
  newContainerNumber: string;
  buttonDisabled = true;
  _selectedInputsIds: string[] = [];
  @Select(ContainerState.containers) containers$: Observable<Container[]>;
  containers = [];
  @Output()
  selectedContainers = new EventEmitter<string[]>();

  constructor(private store: Store,
              private createContainerMutation: CreateContainerMutation) {
  }

  _userId = '';

  @Input()
  set userId(userId: string) {
    this._userId = userId;
    this._disableContainers();
  }

  @Input()
  set selectedInputIDs(inputIds: string[]) {
    this._selectedInputsIds = inputIds;
    this._selectContainers();
  }

  ngOnInit(): void {
    this.store
      .dispatch(new FetchContainerAction());
    this.containers$.subscribe(containers => {
      this.containers = [];
      containers.forEach(container => {
          let selected = false;
          let disabled = true;
          if (this._selectedInputsIds.includes(container.id)) {
            selected = true;
          }
          if (container.user !== null) {
            if (container.user.id === this._userId) {
              disabled = false;
            }
          }
          if (container.user === null) {
            disabled = false;
          }
          if (container.team) {
            disabled = true;
          }
          this.containers.push({ ... container, selected, disabled });
        },
      );
    });
  }

  /**
   * emit the selection of a container
   */
  onCheckboxChanged() {
    this.selectedContainers.emit(this.containers.filter(container => container.selected)
      .map(container => container.id));
  }

  /**
   * create a new container if a valid number is entered
   */
  createNewContainer() {
    this.createContainerMutation.mutate({
        internalNumber: parseInt(this.newContainerNumber, 10),

      })
      .subscribe(({ data }) => {
        this.newContainerNumber = '';
        console.log('got create container', data);
        // refresh store
        this.store
          .dispatch(new FetchContainerAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          console.log(message, i)
        ));
      });

  }

  /**
   * We check if the entered number is valid and if there is no existing container with the same number
   * @param event
   */
  onContainerInputNumberChanged(event) {
    const enteredNumber = parseInt(this.newContainerNumber, 10);
    const existingIds = this.containers.map((container: Container) => container.internalNumber);
    this.buttonDisabled = enteredNumber <= 0 || isNaN(enteredNumber) || existingIds.includes(enteredNumber);
  }

  /**
   * If the container selections changes from outside we have to change the selection in the list
   * @private
   */
  private _selectContainers() {
    this.containers.forEach(container => {
      if (this._selectedInputsIds.includes(container.id)) {
        container.selected = true;
      }
    });
  }

  /**
   * if containers are already assigned to other users we have to disable theme
   * @private
   */
  private _disableContainers() {
    this.containers.forEach(container => {
      if (container.user !== null) {
        if (container.user.id === this._userId) {
          container.disabled = false;
        }
        if (container.user === null) {
          container.disabled = false;
        }
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateBraintreePaymentMutation extends Mutation {
  document = gql`
    mutation createBraintreePayment($amount: String!,$currency:String!,$nonce: String!) {
      createBraintreePayment(amount: $amount,currency:$currency,nonce: $nonce)
    }
  `;
}

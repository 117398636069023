import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Relay } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FetchRelaysAction } from '../../actions/fetch-relays.action';
import { DeleteRelayMutation } from '../../mutations/delete-relay.mutation';
import { RelaysState } from '../../relays.state';
import { RelayService } from '../../relay.service';

@Component({
  selector: 'tt-relays-overview',
  templateUrl: './relays-overview.component.html',
  styleUrls: ['./relays-overview.component.scss'],
})
export class RelaysOverviewComponent implements OnInit {
  displayedColumns: string[] = ['description', 'actions'];
  dataSource: MatTableDataSource<Relay>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(RelaysState.relays) relays: Observable<Relay[]>;


  constructor(private store: Store,
              public dialog: MatDialog,
              private logger: NGXLogger,
              private deleteRelayMutation: DeleteRelayMutation,
              private readonly relaysService: RelayService) {
  }

  ngOnInit() {
    this.store.dispatch(new FetchRelaysAction());
    this.relays.subscribe(relays => {
      this.dataSource = new MatTableDataSource(relays);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRelay(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Dieses Relay wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteRelayMutation.mutate({ id }).subscribe(({ data }) => {
          this.logger.log('got Relay delete data', data);
          this.store
            .dispatch(new FetchRelaysAction());
        }, (error) => {
          error.graphQLErrors.map(({ message }, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });
  }

  testTriggerRelays(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Dieses Relay wirklich triggern?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.relaysService.testTriggerRelais(id);
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { BarcodeList } from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  barcodeLists: BarcodeList[];
}

@Injectable({
  providedIn: 'root',
})
export class AllBarcodeListsQuery extends Query<Response> {
  document = gql`
    query barcodeLists {
      barcodeLists {
        ean
        data
        price
        internalId
        id
      }
    }
  `;
}

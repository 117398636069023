import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Area} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  ownAreas: Area[];
}

@Injectable({
  providedIn: 'root',
})
export class OwnAreasQuery extends Query<Response> {
  document = gql`
      query getOwnAreas {
          getOwnAreas {
              id
              description
              squareMeter
              pricePerSquareMeter
          }
      }
  `;
}

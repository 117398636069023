import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import {
  GPIODevice,
  GPIODeviceUpdateManyWithoutTerminalInput,
  Relay,
  RelayUpdateManyInput,
  Terminal,
  TerminalType,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { TerminalState } from '../../terminal.state';
import { CreateTerminalMutation } from '../../mutations/create-terminal-mutation';
import { UpdateTerminalMutation } from '../../mutations/update-terminal-mutation';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { RelaysState } from '../../../relays/relays.state';
import { FetchRelaysAction } from '../../../relays/actions/fetch-relays.action';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';
import { FetchTerminalAction } from '../../actions/fetch-terminal.action';

@Component({
  selector: 'tt-terminal-detail',
  templateUrl: './terminal-detail.component.html',
  styleUrls: ['./terminal-detail.component.scss'],
})

export class TerminalDetailComponent implements OnInit {

  editMode = false;

  currentTerminal: Terminal;
  terminalForm: FormGroup;
  @Select(GpioDevicesState.gpioDevices) gpioDevices$: Observable<GPIODevice[]>;
  @Select(RelaysState.relays) relays$: Observable<Relay[]>;
  @Select(TerminalState.terminals) terminals$: Observable<Terminal[]>;
  relays: RelayUpdateManyInput = {};
  devices: GPIODeviceUpdateManyWithoutTerminalInput = {};
  initiallySelectedRelays$ = new Subject<[any]>();
  initiallySelectedGpioDevices$ = new Subject<[any]>();
  terminalTypes: TerminalType[] = ['APP', 'DOOR', 'MACHINE', 'BARCODE_READER'];
  private _id: string;

  constructor(private route: ActivatedRoute,
              private store: Store,
              private logger: NGXLogger,
              private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private createTerminalMutation: CreateTerminalMutation,
              private updateTerminalMutation: UpdateTerminalMutation,
  ) {
  }

  ngOnInit() {
    this.terminalForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      relaysToSwitch: [''],
      devices: [''],
    });

    this.store.dispatch([
      new FetchTerminalAction(),
      new FetchRelaysAction(),
      new FetchGpioDevicesAction(),
    ]).subscribe(() => {
      this.route.params.subscribe(params => {
        if (params['id'] !== undefined) {
          this.editMode = true;
          this._id = params['id'];
          this._populateForm(this._id);
        }
      });
    });
  }

  createTerminal() {
    this.createTerminalMutation.mutate({
        name: this.terminalForm.value['name'],
        type: this.terminalForm.value['type'],
        devices: this.devices,
        relaysToSwitch: this.relays,
      })
      .subscribe(({ data }) => {
        this.logger.log('got create terminal', data);
        // refresh store
        this.store
          .dispatch(new FetchTerminalAction());
        this.router.navigate([`/admin/terminal/edit/${data['createTerminal'].id}`]);

      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  updateTerminal() {
    this.updateTerminalMutation.mutate({
        id: this._id,
        name: this.terminalForm.value['name'],
        type: this.terminalForm.value['type'],
        devices: this.devices,
        relaysToSwitch: this.relays,
      })
      .subscribe(({ data }) => {
        this.logger.log('got update terminal', data);
        // refresh store
        this.store
          .dispatch(new FetchTerminalAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  onRelaySelectionChange(event) {
    if (event.length > 0) {
      this.relays = {
        connect: event.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      // @ts-ignore
      this.relays = {
        disconnect: this.currentTerminal.relaysToSwitch.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    }
  }

  onGpioDeviceSelectionChange(event) {
    if (event.length > 0) {
      this.devices = {
        connect: event.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      // @ts-ignore
      this.devices = {
        disconnect: this.currentTerminal.devices.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    }
  }


  private _populateForm(id: string) {
    this.terminals$.subscribe((terminals) => {
      // @ts-ignore
      this.currentTerminal = (terminals).find(terminal => terminal.id === id);

      if (this.currentTerminal) {

        if (this.currentTerminal.devices) {
          console.log(this.currentTerminal)

          this.devices = {
            connect: this.currentTerminal.devices.map(singleDevice => {
              return {
                id: singleDevice.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedGpioDevices$.next(this.currentTerminal.devices);
        }
        if (this.currentTerminal.relaysToSwitch) {
          this.relays = {
            connect: this.currentTerminal.relaysToSwitch.map(relayToSwitch => {
              return {
                id: relayToSwitch.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedRelays$.next(this.currentTerminal.relaysToSwitch);
        }

        this.terminalForm.patchValue({
          name: this.currentTerminal.name,
          type: this.currentTerminal.type,
          relayToSwitch: this.relays,
          devices: this.devices,
        });
      }
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { GPIODevice } from '../../../../../../../../common/interfaces/prisma.binding';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { FetchGpioDevicesAction } from '../../actions/fetch-gpio-devices.action';
import { GpioDevicesState } from '../../gpio-devices.state';
import { DeleteGpioDevicesMutation } from '../../mutations/delete-gpio-devices.mutation';

@Component({
  selector: 'tt-gpio-devices-overview',
  templateUrl: './gpio-devices-overview.component.html',
  styleUrls: ['./gpio-devices-overview.component.scss'],
})
export class GpioDevicesOverviewComponent implements OnInit {
  displayedColumns: string[] = ['description', 'macAddress', 'lastSeen', 'lastIpAddress', 'actions'];
  dataSource: MatTableDataSource<GPIODevice>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Select(GpioDevicesState.gpioDevices) gpioDevices: Observable<GPIODevice[]>;

  constructor(private store: Store,
              public dialog: MatDialog,
              private logger: NGXLogger,
              private deleteGpiODeviceMutation: DeleteGpioDevicesMutation,) {
  }

  ngOnInit() {

    this.store.dispatch(new FetchGpioDevicesAction());
    this.gpioDevices.subscribe(gpioDevices => {
      this.dataSource = new MatTableDataSource(gpioDevices);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteGpioDevice(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Dieses Device wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteGpiODeviceMutation.mutate({ id }).subscribe(({ data }) => {
          this.logger.log('got Gpio Device delete data', data);
          this.store
            .dispatch(new FetchGpioDevicesAction());
        }, (error) => {
          error.graphQLErrors.map(({ message }, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });
  }
}

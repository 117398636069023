import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DecreaseHttpCallCount} from '../actions/decrease-http-call-count.action';
import {IncreaseHttpCallCount} from '../actions/increase-http-call-count.action';

@Injectable()
export class LoadingStateHttpInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new IncreaseHttpCallCount());
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          this.store.dispatch(new DecreaseHttpCallCount());
          return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(new IncreaseHttpCallCount());
        console.log('Error caught in  Loading interceptor ', error);
        return throwError(error);
      }),
    );
  }

}

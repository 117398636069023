import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateContainerMutation extends Mutation {
  document = gql`
      mutation createContainer($internalNumber: Int!,$description:String,$location: String, $user: UserCreateOneWithoutContainerInput, $electricMeter: ElectricMeterCreateOneWithoutContainerInput, $team: TeamCreateOneWithoutContainerInput) {
          createContainer(
              data: {
                  internalNumber: $internalNumber
                  description: $description
                  location: $location
                  electricMeter: $electricMeter
                  team: $team
                  user: $user
              }
          ) {
              id
              internalNumber
          }
      }
  `;
}

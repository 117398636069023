import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractKeysFromEnum',
})
export class ExtractKeysFromEnumPipe implements PipeTransform {
  public transform(value: any): string[] {
    return Object
          .keys(value);
  }
}

import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateGpioDevicesMutation extends Mutation {
  document = gql`
    mutation updateGPIODeviceMutation($id:ID!, $description: String!, $deviceID: String!, $minRSSI: Int!,$macAddress:String, $hasRFIDReader: Boolean,$isBeacon: Boolean,$lastSeen: DateTime,$hasDisplay:Boolean,$hasBarcodeReader:Boolean) {
      updateGPIODevice(where:{
        id:$id
      }
        data: {
          description:$description
          deviceID: $deviceID
          minRSSI: $minRSSI
          macAddress: $macAddress
          hasRFIDReader: $hasRFIDReader
          lastSeen:$lastSeen
          isBeacon: $isBeacon,
          hasDisplay:$hasDisplay,
          hasBarcodeReader:$hasBarcodeReader
        }
      ) {
        id
        description
      }
    }
  `;
}

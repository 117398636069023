import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../common/angular/environments/environment';
import {
  ContainerCreateManyWithoutUserInput,
  ContainerUpdateManyWithoutUserInput,
  Image,
  ImageCreateInput,
  ImageCreateManyInput,
  MeterReading,
  MeterReadingCreateInput,
  MeterReadingCreateManyWithoutUserInput,
  MeterReadingUpdateManyWithoutUserInput,
  UserRole,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { CreateUserMutation } from '../../mutations/create-user.mutation';
import { SendKeycloakInviteMutation } from '../../mutations/send-keycloak-invite.mutation';
import { UpdateUserMutation } from '../../mutations/update-user.mutation';
import { GetSingleUserQuery } from '../../queries/get-single-user.query';
import { UserState } from '../../user.state';

export enum DocumentImagesEnum {
  ID_CARD_IMAGE = 'idCardImages',
  LIABILITY_INSURANCE_IMAGE = 'liabilityInsuranceImages',
  ADDITIONAL_IMAGE = 'additionalDocumentsImages',
  INVOICE_DOCUMENT = 'invoiceDocuments',
}

export interface IUploadedFile {
  mimeType: string;
  fileName: string;
  dropZoneUUID: string;
  s3ID: string;
  documentImageType: DocumentImagesEnum;
}

@Component({
  selector: 'tt-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  @Select(UserState.userRoles) userRoles$: Observable<UserRole[]>;
  profileForm: FormGroup = this.formBuilder.group({
    birthday: ['', Validators.required],
    cameOver: [''],
    city: ['', Validators.required],
    company: [''],
    container: new FormArray([]),
    currency: ['EUR'],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    firstName: ['', Validators.required],
    givenKeys: [''],
    housenumber: ['', Validators.required],
    invoiceID: [''],
    keycloakid: [''],
    lastName: ['', Validators.required],
    notes: [''],
    phone: [''],
    rfidID: [undefined],
    pin: [''],
    sendEmail: [false],
    street: ['', Validators.required],
    username: ['', Validators.required],
    userRoles: ['', Validators.required],
    zip: ['', Validators.required],
  });

  public documentImages = DocumentImagesEnum;
  loadedDocuments: Image[] = [];
  loadedInvoices: Image[] = [];
  loadedIdCardImages: Image[] = [];
  loadedLiabilityInsuranceImages: Image[] = [];
  dropZoneBaseConfig: DropzoneConfigInterface = {
    addRemoveLinks: true,
    autoReset: null,
    clickable: true,
    dictRemoveFile: 'Dokument löschen',
    errorReset: null,
    cancelReset: null,
    acceptedFiles: '.pdf,.jpg,.jpeg,.png',
    createImageThumbnails: true,
    url: `${environment.imageBackend}/image/`,
  };
  idCardDropZoneConfig: DropzoneConfigInterface = {
    ...this.dropZoneBaseConfig,
    paramName: 'idCardImages',
  };
  liabilityInsuranceDropZoneConfig: DropzoneConfigInterface = {
    ...this.dropZoneBaseConfig,
    paramName: 'liabilityInsuranceImages',
  };
  additionalDocumentsDropZoneConfig: DropzoneConfigInterface = {
    ...this.dropZoneBaseConfig,
    paramName: 'additionalDocumentsImages',
  };
  invoicesDropZoneConfig: DropzoneConfigInterface = {
    ...this.dropZoneBaseConfig,
    acceptedFiles: '.pdf',
    paramName: 'invoiceDocuments',
  };

  selectedContainers = [];
  userContainer: string[] = [];
  currentUserId = '';
  currenUserRole = '';
  currentPin = '';

  editMode = false;
  electricMeterReading = new Set<MeterReading>();
  disconnectedElectricMeterReading = new Set<MeterReading>();
  temporaryElectricMeterReading: MeterReading;
  private _id: string;
  private uploadedImages: Map<string, IUploadedFile> = new Map();

  constructor(
    private formBuilder: FormBuilder,
    private createUserMutation: CreateUserMutation,
    private _route: ActivatedRoute,
    private getSingleUserQuery: GetSingleUserQuery,
    private updateUserMutation: UpdateUserMutation,
    private logger: NGXLogger,
    private router: Router,
    private sendKeycloakInviteMutation: SendKeycloakInviteMutation,
    private store: Store,
  ) {}

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params['id'] !== undefined) {
        this.editMode = true;
        this._id = params['id'];
        this._populateForm(this._id);
      }
    });
  }

  containerSelected(event: string[]) {
    this.selectedContainers = event;
  }

  saveUser() {
    const {
      firstName,
      lastName,
      username,
      zip,
      city,
      birthday,
      email,
      street,
      housenumber,
      invoiceID,
      pin,
      rfidID,
      phone,
      notes,
      cameOver,
      givenKeys,
      company,
      currency,
      sendEmail,
    } = this.profileForm.value;
    const selectedUserRole = this.profileForm.value.userRoles;
    const idCardImages = this._createImagesFromUpload(
      this.uploadedImages,
      DocumentImagesEnum.ID_CARD_IMAGE,
    );
    const liabilityInsuranceImages = this._createImagesFromUpload(
      this.uploadedImages,
      DocumentImagesEnum.LIABILITY_INSURANCE_IMAGE,
    );
    const documents = this._createImagesFromUpload(
      this.uploadedImages,
      DocumentImagesEnum.ADDITIONAL_IMAGE,
    );
    const invoices = this._createImagesFromUpload(
      this.uploadedImages,
      DocumentImagesEnum.INVOICE_DOCUMENT,
    );
    let userRoles;
    if (selectedUserRole !== this.currenUserRole && this.editMode) {
      userRoles = {
        connect: { name: selectedUserRole },
        disconnect: { name: this.currenUserRole },
      };
    } else {
      userRoles = { connect: { name: selectedUserRole } };
    }
    const meterReadings: MeterReadingCreateManyWithoutUserInput = this._createReadingsFromInput(
      this.electricMeterReading,
    );

    // prepare selected container ids for connection with user
    const selectedContainerForConnect = this.selectedContainers.map(
      containerId => {
        return { id: containerId };
      },
    );
    const disconnectContainer = [];
    // we find the previously selected containers from the users container to disconnect them from the user
    this.userContainer.forEach(userContainer => {
      if (!this.selectedContainers.includes(userContainer)) {
        disconnectContainer.push({ id: userContainer });
      }
    });

    const updateContainers: ContainerUpdateManyWithoutUserInput = {
      disconnect: disconnectContainer,
      connect: selectedContainerForConnect,
    };
    const createContainers: ContainerCreateManyWithoutUserInput = {
      connect: selectedContainerForConnect,
    };
    const updateMeterReadings: MeterReadingUpdateManyWithoutUserInput = {
      disconnect: Array.from(this.disconnectedElectricMeterReading).map(
        reading => {
          return { id: reading.id };
        },
      ),
      connect: Array.from(this.electricMeterReading)
        .filter(reading => (reading as object).hasOwnProperty('id'))
        .map(reading => {
          return { id: reading.id };
        }),
      create: Array.from(this.electricMeterReading)
        .filter(reading => !(reading as object).hasOwnProperty('id'))
        .map(reading => {
          return {
            meterReading: reading.meterReading,
            entryDate: reading.entryDate,
            kwhPriceOnReading: reading.kwhPriceOnReading,
            meter: { connect: { id: reading.meter.id } },
          };
        }),
    };
    if (!this.editMode) {
      this.createUserMutation
        .mutate({
          firstName,
          lastName,
          username,
          zip,
          city,
          birthday,
          email,
          street,
          housenumber,
          invoiceID,
          userRoles,
          pin: pin !== null ? pin.toString() : '',
          rfidID,
          phone,
          givenKeys,
          cameOver,
          notes,
          company,
          currency,
          documents,
          idCardImages,
          liabilityInsuranceImages,
          container: createContainers,
          invoices,
          meterMeterReading: meterReadings,
        })
        .subscribe(
          ({ data }) => {
            this.logger.debug('got create user data', data);
            if (sendEmail) {
              this._sendKeycloakUserInvite(data['createUser'].keycloakID);
            }
            this.router.navigate([`/admin/user/edit/${data['createUser'].id}`]);
          },
          error => {
            error.graphQLErrors.map(({ message }, i) =>
              // ToDO: Proper Error Handling
              console.log(message, i),
            );
          },
        );
    } else if (this.editMode) {
      const variablesToUpdate: any = {
        firstName,
        lastName,
        username,
        zip,
        city,
        birthday,
        email,
        street,
        housenumber,
        invoiceID,
        userRoles,
        rfidID,
        phone,
        givenKeys,
        cameOver,
        notes,
        company,
        currency,
        documents,
        idCardImages,
        liabilityInsuranceImages,
        container: updateContainers,
        invoices,
        meterMeterReading: updateMeterReadings,
        id: this._id,
      };
      if (pin.toString() !== this.currentPin) {
        variablesToUpdate.pin = pin.toString();
      }
      this.updateUserMutation.mutate(variablesToUpdate).subscribe(
        ({ data }) => {
          console.log('got data', data);
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // ToDO: Proper Error Handling
            console.log(message, i),
          );
        },
      );
    }
  }

  onUploadError(args: any): void {
    this.logger.debug('onUploadError:', args);
  }

  /**
   * We store the uploaded image data into a local array to use this for later user mutations
   * @param args
   * @param type
   */
  onUploadSuccess(args: any, type: DocumentImagesEnum): void {
    this.uploadedImages.set(args[0].upload.uuid, {
      fileName: args[0].name,
      mimeType: args[0].type,
      documentImageType: type,
      dropZoneUUID: args[0].upload.uuid,
      s3ID: args[1][type][0].key,
    });
    this.logger.debug('onUploadSuccess:', args);
    console.log(this.uploadedImages);
  }

  onRemoveFile(args: any): void {
    this.uploadedImages.delete(args.upload.uuid);
    console.log(this.uploadedImages);
    this.logger.debug('onRemoveFile:', args);
  }

  onSendKeycloakInviteClick() {
    const { keycloakid } = this.profileForm.value;
    this._sendKeycloakUserInvite(keycloakid);
  }

  deleteInvoice(s3Id: string) {}

  onAddElectricMeterReading(meterReading: MeterReading) {
    if (meterReading === undefined) {
      this.temporaryElectricMeterReading = undefined;
    } else {
      this.temporaryElectricMeterReading = meterReading;
    }
  }

  onAddElectricMeterReadingClick() {
    this.electricMeterReading.add(
      Object.assign({}, this.temporaryElectricMeterReading),
    );
    this.temporaryElectricMeterReading = undefined;
  }

  onDeleteElectricMeterReadingClick(meterReading: MeterReading) {
    if ((meterReading as object).hasOwnProperty('id')) {
      this.disconnectedElectricMeterReading.add(meterReading);
    }
    this.electricMeterReading.delete(meterReading);
  }

  adminRoleChanged(change) {
    this.profileForm.patchValue({ userRoles: change.value });
  }

  onDateChange(date) {
    this.profileForm.patchValue({ birthday: date.value });
  }

  /**
   * We put the images we uploaded with Dropzone.js into the new createUser Mutation
   * @param imageInputs
   * @param type
   * @private
   */
  private _createImagesFromUpload(
    imageInputs: Map<string, IUploadedFile> = new Map(),
    type: DocumentImagesEnum,
  ): ImageCreateManyInput {
    const tempReturnArray: ImageCreateInput[] = [];
    imageInputs.forEach(imageInput => {
      if (imageInput.documentImageType === type) {
        tempReturnArray.push({
          fileName: imageInput.fileName,
          s3key: imageInput.s3ID,
          mimetype: imageInput.mimeType,
        });
      }
    });
    return { create: tempReturnArray };
  }

  private _createReadingsFromInput(
    readings: Set<MeterReading> = new Set(),
  ): MeterReadingCreateManyWithoutUserInput {
    const tempReturnArray: MeterReadingCreateInput[] = [];
    readings.forEach(reading => {
      tempReturnArray.push({
        meterReading: reading.meterReading,
        meter: { connect: { id: reading.meter.id } },
      });
    });
    return { create: tempReturnArray };
  }

  private _connectContainersFromSelection() {}

  private _populateForm(userId: string) {
    this.getSingleUserQuery
      .watch(
        { id: userId },
        {
          fetchPolicy: 'network-only',
        },
      )
      .valueChanges.subscribe(data => {
        const {
          firstName,
          lastName,
          username,
          zip,
          city,
          birthday,
          email,
          street,
          housenumber,
          userRoles,
          pin,
          keycloakID,
          rfidID,
          phone,
          givenKeys,
          cameOver,
          notes,
          company,
          currency,
          documents,
          idCardImages,
          liabilityInsuranceImages,
          container,
          invoices,
          id,
          invoiceID,
          meterMeterReading,
        } = data['data']['user'];
        this.currentUserId = id;
        this.loadedDocuments = documents;
        this.loadedIdCardImages = idCardImages;
        this.loadedLiabilityInsuranceImages = liabilityInsuranceImages;
        this.loadedInvoices = invoices;
        this.userContainer = container.map(
          singleContainer => singleContainer.id,
        );
        this.selectedContainers = this.userContainer;
        this.currenUserRole = userRoles[0].name;
        this.currentPin = pin;

        // populate meter readings
        (meterMeterReading as Array<any>).forEach(
          (meterReading: MeterReading) => {
            this.electricMeterReading.add(meterReading);
          },
        );
        this.profileForm.patchValue({
          firstName,
          lastName,
          username,
          zip,
          city,
          birthday,
          email,
          street,
          housenumber,
          keycloakid: keycloakID,
          userRoles: userRoles[0].name,
          rfidID,
          phone,
          givenKeys,
          cameOver,
          notes,
          company,
          currency,
          pin,
          invoiceID,
        });
      });
  }

  private _sendKeycloakUserInvite(keycloakId: string) {
    this.sendKeycloakInviteMutation.mutate({ keycloakId }).subscribe(
      ({ data }) => {
        this.logger.debug('Keycloak invite sended', data);
      },
      error => {
        error.graphQLErrors.map(({ message }, i) =>
          // ToDO: Proper Error Handling
          console.log(message, i),
        );
      },
    );
  }
}

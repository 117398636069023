import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class ToggleTimeEntryApprovalMutation extends Mutation {
  document = gql`
    mutation createTimeEntry($id: ID, $approval: Boolean!) {
      updateTimeEntry(where: { id: $id }, data: { isApproved: $approval }) {
        id
        project {
          id
          projectNumber
        }
        description
        date
        isApproved
        bookedTime
      }
    }
  `;
}

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { BarcodeItem, BarcodeList } from '../../../../../../common/interfaces/prisma.binding';
import { AuthService } from '../../shared/services/auth.service';
import { FetchBarcodesAction } from './actions/fetch-barcodes.action';
import { AllBarcodesQuery } from './queries/all-barcodes.query';
import { FetchBarcodeListItemsAction } from './actions/fetch-barcode-list-items.action';
import { AllBarcodeListsQuery } from './queries/all-barcode-lists.query';
import { Injectable } from '@angular/core';

export interface BarcodesModel {
  barcodes: BarcodeItem[];
  barcodeListItems: BarcodeList[];

}

@State<BarcodesModel>({
  name: 'barcodes',
  defaults: {
    barcodes: [],
    barcodeListItems: [],
  },
})
@Injectable()
export class BarcodeState {
  constructor(private authService: AuthService,
              private allBarcodesQuery: AllBarcodesQuery,
              private allBarcodeListItems: AllBarcodeListsQuery,
              private logger: NGXLogger) {
  }

  @Selector()
  static barcodes(state: BarcodesModel) {
    return state.barcodes;
  }

  @Selector()
  static barcodeListItems(state: BarcodesModel) {
    return state.barcodeListItems;
  }

  @Action(FetchBarcodesAction)
  async getBarcodes({ patchState }: StateContext<BarcodesModel>, action: FetchBarcodesAction) {
    return new Promise(async (resolve, reject) => {
      this.allBarcodesQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({ data, loading }: { data: any, loading: boolean }) => resolve(patchState({ barcodes: data.barcodeItems })));
    });
  }

  @Action(FetchBarcodeListItemsAction)
  async getBarcodeListItems({ patchState }: StateContext<BarcodesModel>) {
    return new Promise(async (resolve, reject) => {
      this.allBarcodeListItems.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({ data, loading }: { data: any, loading: boolean }) => resolve(patchState({ barcodeListItems: data.barcodeLists })));
    });
  }

}

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';

import gql from 'graphql-tag';
import {MyWorkshopStateModel} from '../my-workshop.state';

export interface Response {
  getMyWorkshopData: MyWorkshopStateModel[];
}

@Injectable({
  providedIn: 'root',
})
export class GetUserWorkshopDataQuery extends Query<Response> {
  document = gql`
      query {getMyWorkshopData}
  `;
}

import {Component} from '@angular/core';


@Component({
  selector: 'tt-add-payment-methods',
  templateUrl: './add-payment-methods.component.html',
  styleUrls: ['./add-payment-methods.component.scss'],
})
export class AddPaymentMethodsComponent {


}

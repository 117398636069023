import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {PaymentProviderEnum} from '../../../../../../../../common/enums/payment-provider.enum';
import {AuthStateModel} from '../../../../shared/models/auth-state.model';
import {AuthState} from '../../../../shared/state/auth.state';
import {FetchUserPaymentMethodsAction} from '../../actions/fetch-user-payment-methods.action';
import {SetTopUpPaymentMethodAction} from '../../actions/set-top-up-payment-method.action';
import {TopUpCreditAction} from '../../actions/top-up-credit.action';
import {IAbstractPaymentMethod} from '../../interfaces/abstract-payment-method.interface';
import {ChargingPaymentMethodInterface} from '../../interfaces/charging-payment-method.interface';
import {PaymentState} from '../../payment.state';
import {PaymentMethodBraintreeComponent} from '../payment-method-braintree/payment-method-braintree.component';
import {PaymentMethodCreditCardComponent} from '../payment-method-credit-card/payment-method-credit-card.component';
import {PaymentMethodPaypalComponent} from '../payment-method-paypal/payment-method-paypal.component';

@Component({
  selector: 'tt-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit, OnDestroy {
  @Select(PaymentState.selectedTopUpPaymentMethod) selectedTopUpPaymentMethod$: Observable<ChargingPaymentMethodInterface>;
  @Select(PaymentState.customTopUpValue) customTopUpValue$: Observable<number>;
  @Select(AuthState.userCredit) userCredit$: Observable<number>;
  @Select(AuthState.userCurrency) userCurrency$: Observable<string>;
  @Select(AuthState.userDetails) userDetails$: Observable<AuthStateModel>;

  userDetailSubscription: Subscription;
  paymentMethods: IAbstractPaymentMethod[];

  constructor(private store: Store) {
    this.userDetailSubscription = this.userDetails$.subscribe(userDetails => {
      this.store.dispatch(new FetchUserPaymentMethodsAction(userDetails.id));
    });

  }

  ngOnInit() {
    this.paymentMethods = [
      {
        name: PaymentProviderEnum.CREDIT_CARD,
        component: PaymentMethodCreditCardComponent,
      }, {
        name: PaymentProviderEnum.BRAINTREE,
        component: PaymentMethodBraintreeComponent,
      }, {
        name: PaymentProviderEnum.PAYPAL,
        component: PaymentMethodPaypalComponent,
      },
    ];

  }



  ngOnDestroy(): void {
    this.userDetailSubscription.unsubscribe();
  }

  paymentMethodChanged($event: ChargingPaymentMethodInterface) {
    this.store.dispatch(new SetTopUpPaymentMethodAction($event));
  }
}

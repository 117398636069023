import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Image } from '../../../../../../../../common/interfaces/prisma.binding';
import { AuthState } from '../../../../shared/state/auth.state';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { BillomatDtoInterface } from '../../../../../../../../common/interfaces/billomat-dto.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BillomatService } from '../../../../shared/services/billomat.service';

export interface YearMonthData {
  invoices?: Partial<Image>[];
  month: number;
  monthLocal: string;
  year: number;
}

@Component({
  selector: 'tt-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, AfterViewInit {
  @Select(AuthState.userInvoices) userInvoices$: Observable<[]>;
  @Select(AuthState.billomatInvoices) billomatInvoices$: Observable<[]>;
  invoiceID: string;
  displayedColumns: string[] = [
    'select',
    'date',
    'invoice_number',
    'title',
    'total',
    'download',
  ];
  dataSource = new MatTableDataSource<BillomatDtoInterface>();
  selection = new SelectionModel<BillomatDtoInterface>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  accordionStep = 0;
  processedInvoiceData: YearMonthData[] = [];

  constructor(
    private readonly store: Store,
    private readonly billomatService: BillomatService,
  ) {
    moment.locale('de');
    this.billomatInvoices$.subscribe((invoices) => {
      this.dataSource.data = invoices;
    });
    this.userInvoices$.subscribe((invoiceData: Partial<Image>[]) => {
      // invoiceData = this.mockdata;
      // We first iterate over all invoices to extract month and year tuples
      invoiceData.forEach((mock: Image) => {
        const dateObject: YearMonthData = {
          month: parseInt(moment(mock.createdAt).format('MM').toString(), 10),
          year: parseInt(moment(mock.createdAt).format('YYYY').toString(), 10),
          monthLocal: moment(mock.createdAt).format('MMMM').toString(),
        };
        // we check if data month/year tuple is already in the array
        if (
          this.processedInvoiceData.find(
            (insertedDates) =>
              insertedDates.year === dateObject.year &&
              insertedDates.month === dateObject.month,
          ) === undefined
        ) {
          this.processedInvoiceData.push(dateObject);
        }
      });
      // sort by year and month
      this.processedInvoiceData.sort((a, b) => {
        return b.year - a.year || b.month - a.month;
      });
      // we then iterate over the month/year tuples and find and attach the corresponding invoices
      this.processedInvoiceData.map((actualInvoices) => {
        actualInvoices.invoices = invoiceData.filter(
          (mockDate) =>
            actualInvoices.year ===
              parseInt(
                moment(mockDate.createdAt).format('YYYY').toString(),
                10,
              ) &&
            actualInvoices.month ===
              parseInt(moment(mockDate.createdAt).format('MM').toString(), 10),
        );
        // sort by day in month (newest first)
        actualInvoices.invoices.sort((a, b) => {
          return (
            parseInt(moment(b.createdAt).format('DD').toString(), 10) -
            parseInt(moment(a.createdAt).format('DD').toString(), 10)
          );
        });
        return actualInvoices;
      });
    });
  }

  ngOnInit(): void {
    this.invoiceID = this.store.selectSnapshot(AuthState.invoiceID);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setStep(index: number) {
    this.accordionStep = index;
  }

  nextStep() {
    this.accordionStep++;
  }

  prevStep() {
    this.accordionStep--;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: BillomatDtoInterface): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  async downloadSingleInvoice(id: string) {
    const pdfData = await this.billomatService.getInvoiceByBillomatInvoiceId(
      parseInt(id, 10),
    );
    this.downloadPdf(pdfData.base64file, pdfData.filename);
  }

  async downloadBillomatSelection() {
    const pdfData = await this.billomatService.getAllBillomatInvoices(
      this.selection.selected.map((invoice) => parseInt(invoice.id, 10)),
    );
    this.downloadZip(pdfData.base64file, pdfData.filename);
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }

  downloadZip(base64String, fileName) {
    const source = `data:application/zip;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}`;
    link.click();
  }
}

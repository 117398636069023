import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { Sources } from '../../../../../../../common/interfaces/stripe.bindings';

export interface Response {
  paymentsMethods: Sources[];
}

@Injectable({
  providedIn: 'root',
})
export class GetUserPaymentMethodsQuery extends Query<Response> {
  document = gql`
    query paymentMethodsuser($id:ID!) {
      paymentMethods(where: {
        id: $id}) {
        id
        card {
          last4
          exp_year
          exp_month
          brand
        }
      }
    }
  `;
}

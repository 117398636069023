import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateAreaMutation extends Mutation {
  document = gql`
      mutation createArea($description: String!, $pricePerSquareMeter:Float!,$squareMeter: Float!, $user: UserCreateOneInput,$team:TeamCreateOneInput,$meter:ElectricMeterCreateOneInput) {
          createArea(
              data: {
                  description:$description
                  pricePerSquareMeter:$pricePerSquareMeter
                  squareMeter:$squareMeter
                  meter:$meter
                  user: $user
                  team: $team
              }
          ) {
              id
              description
          }
      }
  `;
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {GpioDevicesDetailComponent} from './components/gpio-devices-detail/gpio-devices-detail.component';
import {GpioDevicesOverviewComponent} from './components/gpio-devices-overview/gpio-devices-overview.component';
import { GpioDevicesNotRegisteredComponent } from './components/gpio-devices-not-registered/gpio-devices-not-registered.component';

@NgModule({
  declarations: [GpioDevicesOverviewComponent, GpioDevicesDetailComponent, GpioDevicesNotRegisteredComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class GpioDevicessModule {
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RelaysDetailComponent } from './components/relays-detail/relays-detail.component';
import { RelaysOverviewComponent } from './components/relays-overview/relays-overview.component';
import { RelayService } from './relay.service';

@NgModule({
  declarations: [RelaysOverviewComponent, RelaysDetailComponent],
  providers: [RelayService],
  imports: [CommonModule, SharedModule],
})
export class RelayssModule {
}

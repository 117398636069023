import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { StripeCustomer } from '../../../../../../common/interfaces/stripe.bindings';

export interface Response {
  stripeUserByContext: StripeCustomer;
}

@Injectable({
  providedIn: 'root',
})
export class GetStripeUserByContextQuery extends Query<Response> {
  document = gql`
    query stripeUserByContext {
      stripeUserByContext {
        subscriptions {
          data {
            id
            items {
              data {
                plan {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {AreaDetailComponent} from './components/area-detail/area-detail.component';
import {AreaOverviewComponent} from './components/area-overview/area-overview.component';

@NgModule({
  declarations: [AreaOverviewComponent, AreaDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class AreaModule {
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Image } from '../../../../../../../../common/interfaces/prisma.binding';
import { AuthState } from '../../../../shared/state/auth.state';
import { UpdateUserPinMutation } from '../../../../admin/user/mutations/update-user-pin.mutation';
import { AuthStateModel } from '../../../../shared/models/auth-state.model';


export interface YearMonthData {
  invoices?: Partial<Image>[];
  month: number;
  monthLocal: string;
  year: number;
}

@Component({
  selector: 'tt-myaccount',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  @Select(AuthState.userDetails) userDetails$: Observable<AuthStateModel>;

  userPin: number = null;
  userId = '';
  userSubscription: Subscription;

  constructor(private readonly store: Store,
              private updateUserPinMutation: UpdateUserPinMutation,
  ) {
  }

  ngOnInit(): void {
    this.userSubscription = this.userDetails$.subscribe(userDetail => this.userId = userDetail.id)
  }

  changePin() {
    console.log(this.userPin);
    this.updateUserPinMutation
      .mutate({
        id: this.userId,
        pin: this.userPin.toString(),
      })
      .subscribe(({ data }) => {
        console.log('got data', data);
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // ToDO: Proper Error Handling
          console.log(message, i)
        ));
      });

  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}

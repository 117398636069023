import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateRelayMutation extends Mutation {
  document = gql`
      mutation createRelay($description: String!, $pin:Int!,$connectedGPIODevice: GPIODeviceCreateOneWithoutConnectedRelayInput, $users: UserCreateManyInput,$team:TeamCreateOneInput,$gpioController:GPIOController, $autoRelease: Boolean, $autoReleaseTimeInMs: Int,$autoToggle:Boolean ) {
          createRelay(
              data: {
                  description:$description
                  pin:$pin
                  connectedGPIODevice:$connectedGPIODevice
                  users: $users
                  team: $team
                  gpioController: $gpioController
                  autoRelease: $autoRelease
                autoReleaseTimeInMs:$autoReleaseTimeInMs
                autoToggle: $autoToggle
              }
          ) {
              id
              description
          }
      }
  `;
}

import {NgModule} from '@angular/core';
import {Store} from '@ngxs/store';
import {OAuthService} from 'angular-oauth2-oidc';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink, split} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../common/angular/environments/environment';
import {AddMessageToSnackbarQueueAction} from './modules/shared/actions/add-message-to-snackbar-queue.action';
import {ESnackBarEnum} from './modules/shared/enums/e-snack-bar.enum';

const cache = new InMemoryCache();
const uri = environment.apiURL;
const wsUri = environment.apiWSURL;


export function createApollo(httpLink: HttpLink, oauthService: OAuthService, logger: NGXLogger, store: Store) {
  interface Definintion {
    kind: string;
    operation?: string;
  }

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.map(({message, locations, path}) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          const messageString = `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`;
          store.dispatch(new AddMessageToSnackbarQueueAction({
            title: '[GraphQL error]',
            message: messageString,
            snackbarType: ESnackBarEnum.ERROR_SNACKBAR,
          }));
        },
      )
      ;
    if (networkError) logger.error(networkError);
  });


  const subscriptionLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {authorization: `Bearer ${oauthService.getAccessToken()}`},
      },
    },
  });
  const httpLinkWithErrorHandling = ApolloLink.from([
    errorLink,
    httpLink.create({uri}), // ToDo: change https,
  ]);
  const link = split(
    ({query}) => {

      const {kind, operation}: Definintion = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    subscriptionLink,
    httpLinkWithErrorHandling,
  );
  return {
    link,
    cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService, NGXLogger, Store],
    },
  ],
})
export class GraphQLModule {

}

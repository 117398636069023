import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Terminal } from '../../../../../../common/interfaces/prisma.binding';
import { FetchTerminalAction } from './actions/fetch-terminal.action';
import { AllTerminalQuery } from './queries/all-terminal.query';
import { Injectable } from '@angular/core';

export interface TerminalStateModel {

  terminals: Terminal[];

}

@State<TerminalStateModel>({
  name: 'terminals',
  defaults: {
    terminals: [],
  },
})
@Injectable()
export class TerminalState {
  constructor(private allTerminalQuery: AllTerminalQuery) {
  }

  @Selector()
  static terminals(state: TerminalStateModel) {
    return state.terminals;
  }


  @Action(FetchTerminalAction)
  async getContainers({ patchState }: StateContext<TerminalStateModel>) {
    return new Promise(async (resolve, reject) => {
      this.allTerminalQuery.watch({}, {
        fetchPolicy: 'network-only',
      }).valueChanges.subscribe(({ data, loading }: { data: any, loading: boolean }) => resolve(patchState({ terminals: data.terminals })));
    });
  }
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateTeamMutation extends Mutation {
  document = gql`
      mutation updateTeam($id:ID!, $name: String!, $users: UserUpdateManyWithoutTeamInput, $container: ContainerUpdateManyWithoutTeamInput,$meterReadings:MeterReadingUpdateManyWithoutTeamInput) {
          updateTeam(where:{
              id:$id
          }
              data: {
                  name:$name
                  users: $users
                  container: $container
                  meterReadings:$meterReadings
              }
          ) {
              id
              name
          }
      }
  `;
}

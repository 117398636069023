import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../../common/angular/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { FetchGpioDevicesAction } from '../../actions/fetch-gpio-devices.action';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { CreateGpioDevicesMutation } from '../../mutations/create-gpio-devices-mutation';
import { v4 } from 'uuid';

@Component({
  selector: 'tt-gpio-devices-not-registered',
  templateUrl: './gpio-devices-not-registered.component.html',
  styleUrls: ['./gpio-devices-not-registered.component.scss'],
})
export class GpioDevicesNotRegisteredComponent implements OnInit {
  displayedColumns: string[] = ['mac', 'ip', 'lastSeen', 'actions'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store,
    private logger: NGXLogger,
    private createGpioDeviceMutation: CreateGpioDevicesMutation,
    private router: Router,
  ) {}

  ngOnInit() {
    this.http
      .get(`${environment.imageBackend}/gpiodevice/notregisterdevice`)
      .subscribe((devices: any) => {
        this.dataSource = new MatTableDataSource(devices);
      });
  }

  registerDevice(mac: string, timeStamp: Date) {
    this.createGpioDeviceMutation
      .mutate({
        description: '',
        deviceID: v4().toString(),
        minRSSI: 60,
        macAddress: mac,
        hasReader: false,
        isBeacon: false,
        lastSeen: timeStamp,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create gpio device', data);
          // refresh store
          this.store.dispatch(new FetchGpioDevicesAction());
          this.router.navigate([
            `/admin/gpio-devices/edit/${data['createGPIODevice'].id}`,
          ]);
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }
}

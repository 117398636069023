import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateTeamMutation extends Mutation {
  document = gql`
    mutation createTeam(
      $name: String!
      $users: UserCreateManyWithoutTeamInput
      $container: ContainerCreateManyWithoutTeamInput
      $meterReadings: MeterReadingCreateManyWithoutTeamInput
    ) {
      createTeam(
        data: {
          name: $name
          users: $users
          container: $container
          meterReadings: $meterReadings
        }
      ) {
        id
        name
      }
    }
  `;
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { GPIODevice } from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { CreateGpioDevicesMutation } from '../../mutations/create-gpio-devices-mutation';
import { UpdateGpioDevicesMutation } from '../../mutations/update-gpio-devices-mutation';
import { v4 } from 'uuid';

@Component({
  selector: 'tt-gpio-devices-detail',
  templateUrl: './gpio-devices-detail.component.html',
  styleUrls: ['./gpio-devices-detail.component.scss'],
})

export class GpioDevicesDetailComponent implements OnInit {

  @Select(GpioDevicesState.gpioDevices) gpioDevices$: Observable<GPIODevice[]>;
  editMode = false;

  gpioDevices: GPIODevice[];
  currentGpioDevice: GPIODevice;
  gpioDevice;

  initiallySelectedGpioDevice$ = new Subject<[any]>();
  gpioForm: FormGroup;


  private _id: string;

  constructor(private route: ActivatedRoute,
              private store: Store,
              private logger: NGXLogger,
              private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private createGpioDeviceMutation: CreateGpioDevicesMutation,
              private updateGpioDeviceMutation: UpdateGpioDevicesMutation,
  ) {
  }

  ngOnInit() {
    this.gpioForm = this.formBuilder.group({
      description: [''],
      deviceID: [v4().toString(), Validators.required],
      minRSSI: [0, Validators.required],
      macAddress: ['', Validators.required],
      lastSeen: new FormControl({ value: '', disabled: true }),
      hasRFIDReader: [false],
      isBeacon: [false],
      hasDisplay: [false],
      hasBarcodeReader: [false],
    });
    this.store.dispatch([

      new FetchGpioDevicesAction(),
    ]).subscribe(() => {
      this.gpioDevice = this.store.selectSnapshot(GpioDevicesState.gpioDevices);

      this.route.params.subscribe(params => {
        if (params['id'] !== undefined) {
          this.editMode = true;
          this._id = params['id'];
          this._populateForm(this._id);
        }
      });
    });
  }

  createGpioDevice() {

    this.createGpioDeviceMutation.mutate({
        description: this.gpioForm.value['description'].toString(),
        deviceID: this.gpioForm.value['deviceID'].toString(),
        minRSSI: this.gpioForm.value['minRSSI'],
        lastSeen: this.gpioForm.value.lastSeen,
        hasRFIDReader: this.gpioForm.value.hasRFIDReader,
        isBeacon: this.gpioForm.value.isBeacon,
        macAddress: this.gpioForm.value.macAddress.toString(),
        hasDisplay: this.gpioForm.value.hasDisplay,
        hasBarcodeReader: this.gpioForm.value.hasBarcodeReader,
      })
      .subscribe(({ data }) => {
        this.logger.log('got create gpio device', data);
        // refresh store
        this.store
          .dispatch(new FetchGpioDevicesAction());
        this.router.navigate([`/admin/gpio-devices/edit/${data['createGPIODevice'].id}`]);

      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  updateGpioDevice() {

    this.updateGpioDeviceMutation.mutate({
        id: this._id,
        description: this.gpioForm.value['description'].toString(),
        deviceID: this.gpioForm.value['deviceID'].toString(),
        minRSSI: this.gpioForm.value['minRSSI'],
        lastSeen: this.gpioForm.value.lastSeen,
        hasRFIDReader: this.gpioForm.value.hasRFIDReader,
        isBeacon: this.gpioForm.value.isBeacon,
        macAddress: this.gpioForm.value.macAddress.toString(),
        hasDisplay: this.gpioForm.value.hasDisplay,
        hasBarcodeReader: this.gpioForm.value.hasBarcodeReader,
      })
      .subscribe(({ data }) => {
        this.logger.log('got update gpiodevice', data);
        // refresh store
        this.store
          .dispatch(new FetchGpioDevicesAction());
      }, (error) => {
        error.graphQLErrors.map(({ message }, i) => (
          // proper error handling
          this.logger.error(message, i)
        ));
      });

  }

  createDeviceConfig() {
    const sJson = JSON.stringify({
      description: this.gpioForm.value['description'].toString(),
      deviceID: this.gpioForm.value['deviceID'].toString(),
    });
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson));
    element.setAttribute('download', 'tt-beacon-config.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  private _populateForm(id: string) {
    this.gpioDevices$.subscribe((gpioDevices) => {
      // @ts-ignore
      this.currentGpioDevice = (gpioDevices).find(gpioDevice => gpioDevice.id === id);
      if (this.currentGpioDevice) {
        this.gpioForm.patchValue({
          description: this.currentGpioDevice.description,
          deviceID: this.currentGpioDevice.deviceID,
          minRSSI: this.currentGpioDevice.minRSSI,
          lastSeen: this.currentGpioDevice.lastSeen,
          hasRFIDReader: this.currentGpioDevice.hasRFIDReader,
          isBeacon: this.currentGpioDevice.isBeacon,
          macAddress: this.currentGpioDevice.macAddress,
          hasDisplay: this.currentGpioDevice.hasDisplay,
          hasBarcodeReader: this.currentGpioDevice.hasBarcodeReader,
        });
      }
    });
  }
}

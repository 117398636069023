import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { PaymentProviderEnum } from '../../../../../../../../common/enums/payment-provider.enum';
import { Data } from '../../../../../../../../common/interfaces/stripe.bindings';
import { TopUpCreditAction } from '../../actions/top-up-credit.action';
import { ChargingPaymentMethodInterface } from '../../interfaces/charging-payment-method.interface';
import { PaymentMethodInterface } from '../../interfaces/payment-method.interface';
import { PaymentState } from '../../payment.state';

@Component({
  selector: 'tt-payment-method-credit-card',
  templateUrl: './payment-method-credit-card.component.html',
  styleUrls: ['./payment-method-credit-card.component.scss'],
})
export class PaymentMethodCreditCardComponent implements OnInit, OnDestroy, PaymentMethodInterface {

  paymentConfiguration$ = new Subject<ChargingPaymentMethodInterface>();
  disabled$ = new BehaviorSubject<boolean>(true);
  paymentMethodDisabled$ = new BehaviorSubject<boolean>(false);

  @Select(PaymentState.selectedTopUpPaymentMethod) selectedTopUpPaymentMethod$: Observable<ChargingPaymentMethodInterface>;
  @Select(PaymentState.paymentMethods) creditCards$: Observable<Data[]>;
  @Select(PaymentState.customTopUpValue) customTopUpValue$: Observable<number>;

  selectedPaymentMethod: string;
  selectedTopUpPaymentMethodSubscription: Subscription;

  constructor(private store: Store) {
    this.creditCards$.subscribe(creditcards => {
      if (creditcards.length === 0) {
        this.paymentMethodDisabled$.next(true);
      }
      if (creditcards.length > 0) {
        this.paymentMethodDisabled$.next(false);
      }
    });
  }

  ngOnInit() {
    this.selectedTopUpPaymentMethodSubscription =
      this.selectedTopUpPaymentMethod$.subscribe(selectedPaymendMethod => {
        this.selectedPaymentMethod = selectedPaymendMethod.stripePaymentSource;
      });
  }

  paymentMethodChanged() {
    this.paymentConfiguration$.next({
      paymentProvider: PaymentProviderEnum.CREDIT_CARD,
      stripePaymentSource: this.selectedPaymentMethod,
    });
  }

  topUpCredit() {
    this.store.dispatch(new TopUpCreditAction());
  }

  ngOnDestroy(): void {
    this.selectedTopUpPaymentMethodSubscription.unsubscribe();
  }

}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateContainerMutation extends Mutation {
  document = gql`
      mutation updateContainer($id:ID!, $internalNumber: Int!,$description:String,$location: String, $user: UserUpdateOneWithoutContainerInput, $electricMeter: ElectricMeterUpdateOneWithoutContainerInput, $team: TeamUpdateOneWithoutContainerInput) {
          updateContainer(where:{
              id:$id
          }
              data: {
                  internalNumber: $internalNumber
                  electricMeter: $electricMeter
                  description: $description
                  location: $location
                  team: $team
                  user: $user
              }
          ) {
              id
              internalNumber
          }
      }
  `;
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreatePaypalAuthorizationMutation extends Mutation {
  document = gql`
    mutation createPaypalAuthorization($orderId: String!,$payerId:String!) {
      createPaypalAuthorization(orderId: $orderId,payerId:$payerId)
    }
  `;
}

import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {IPayPalConfig} from 'ngx-paypal';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PaymentProviderEnum} from '../../../../../../../../common/enums/payment-provider.enum';
import {ChargingPaymentMethodInterface} from '../../interfaces/charging-payment-method.interface';
import {PaymentMethodInterface} from '../../interfaces/payment-method.interface';
import {CreatePaypalAuthorizationMutation} from '../../mutations/create-paypal-authorization.mutation';
import {CreatePaypalPaymentMutation} from '../../mutations/create-paypal-payment.mutation';
import {PaymentState} from '../../payment.state';


@Component({
  selector: 'tt-payment-method-braintree',
  templateUrl: './payment-method-paypal.component.html',
  styleUrls: ['./payment-method-paypal.component.scss'],
})
export class PaymentMethodPaypalComponent implements OnInit, PaymentMethodInterface {
  @Select(PaymentState.customTopUpValue) topUpValue$: Observable<number>;

  paymentConfiguration$ = new Subject<ChargingPaymentMethodInterface>();
  disabled$ = new BehaviorSubject<boolean>(true);

  paymentMethodDisabled$ = new BehaviorSubject<boolean>(true);
  public payPalConfig ?: IPayPalConfig;
  topUpValue: string;

  constructor(private store: Store,
              private createPaypalPaymentMutation: CreatePaypalPaymentMutation,
              private createPaypalAuthorizationMutation: CreatePaypalAuthorizationMutation) {

    this.disabled$.subscribe(disabled => {
      if (!disabled) {
        this.paymentConfiguration$.next({
          paymentProvider: PaymentProviderEnum.PAYPAL,
        });
      }
    });
    this.topUpValue$.subscribe(value => this.topUpValue = (value / 100).toString(10));

  }

  ngOnInit() {
    this.initConfig();
  }

  scriptLoaded() {
    this.paymentMethodDisabled$.next(false);
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'EUR',
      clientId: 'AcN9DknIXzzL4trm-3M0ESoaBPumNbYPPVr4OD_w3KmEyxyp5q1LcfU3IVpxHyPyCD5O4E8o5GsNPQh2',
      createOrderOnServer: (data) => this._createPaypalPayment(),
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
      },
      authorizeOnServer: (approveData) => this._authorizeOnServer(approveData)
        .then(data => {
        }),
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        // this.showCancel = true;

      },
      onError: err => {
        console.log('OnError', err);
        // this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        // this.resetStatus();
      },
    };
  }

  private _createPaypalPayment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.createPaypalPaymentMutation
        .mutate({
          amount: this.topUpValue,
          currency: 'EUR',
        })
        .subscribe(({data}) => {
          // @ts-ignore
          resolve(data.createPaypalPayment);
        }, (error) => {
          error.graphQLErrors.map(({message}, i) => (
            // ToDO: Proper Error Handling
            console.log(message, i)
          ));
          reject();
        });
    });
  }

  private _authorizeOnServer(approvedData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.createPaypalAuthorizationMutation
        .mutate({
          orderId: approvedData.orderID,
          payerId: approvedData.payerID,
        })
        .subscribe(({data}) => {
          // @ts-ignore
          console.log(JSON.parse(data.createPaypalAuthorization));
          // @ts-ignore
          resolve(JSON.parse(data.createPaypalAuthorization));
        }, (error) => {
          error.graphQLErrors.map(({message}, i) => (
            // ToDO: Proper Error Handling
            console.log(message, i)
          ));
          reject();
        });
    });

  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {ContainerDetailComponent} from './components/container-detail/container-detail.component';
import {ContainerOverviewComponent} from './components/container-overview/container-overview.component';

@NgModule({
  declarations: [ContainerOverviewComponent, ContainerDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class ContainerModule {
}

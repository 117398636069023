import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Select} from '@ngxs/store';
import {Subscription} from 'apollo-client/util/Observable';
import {Observable} from 'rxjs';
import {StripeInvoice} from '../../../../../../../../common/interfaces/stripe.bindings';
import {PaymentState} from '../../payment.state';

@Component({
  selector: 'tt-top-up-overview',
  templateUrl: './top-up-overview.component.html',
  styleUrls: ['./top-up-overview.component.scss'],
})
export class TopUpOverviewComponent implements OnInit {
  electricMeters: Subscription;
  displayedColumns: string[] = ['date', 'amount', 'receiptUrl'];
  dataSource: MatTableDataSource<StripeInvoice>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @Select(PaymentState.topUpPayments) topUps$: Observable<any>;

  constructor() {
  }

  ngOnInit() {
    this.topUps$
      .subscribe(data => {
        console.log(data);
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

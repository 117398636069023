import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';

import gql from 'graphql-tag';
import { User } from '../../../../../../common/interfaces/prisma.binding';

export interface Response {
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class GetUserByKeycloakIdQuery extends Query<Response> {
  document = gql`
    query userByKcId($keycloakID: String!) {
      user(where: { keycloakID: $keycloakID }) {
        id
        currency
        credit
        email
        firstName
        invoiceID
        invoices {
          createdAt
          s3key
          mimetype
          fileName
        }
        keycloakID
        lastName
        city
        street
        zip
        housenumber
        meterMeterReading {
          id
          meterReading
          meter {
            id
            address
            totalKwh
            totalSystemPower
          }
        }
        birthday
        username
        stripeID
        keycloakID
        userRoles {
          name
        }
        team {
          id
          name
          meterReadings {
            meter {
              address
            }
          }
        }
      }
    }
  `;
}

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';

import gql from 'graphql-tag';
import {AbstractSubscription} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  abstractSubscriptions: AbstractSubscription[];
}

@Injectable({
  providedIn: 'root',
})
export class AbstractSubscriptionsQuery extends Query<Response> {
  document = gql`
    query abstractSubscriptions {
      abstractSubscriptions {
        id
        name
        currency
        price
        description
        images {
          id
          fileName
          pictureUrl
        }
      }
    }
  `;
}

import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class DeleteTeamMutation extends Mutation {
  document = gql`
      mutation deleteTeam($id: ID!) {
          deleteTeam(where: {id: $id}) {
              id
          }
      }
  `;
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Select, Store} from '@ngxs/store';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {Area} from '../../../../../../../../common/interfaces/prisma.binding';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {FetchAreasAction} from '../../actions/fetch-areas.action';
import {AreaState} from '../../area.state';
import {DeleteAreaMutation} from '../../mutations/delete-area.mutation';


@Component({
  selector: 'tt-area-overview',
  templateUrl: './area-overview.component.html',
  styleUrls: ['./area-overview.component.scss'],
})
export class AreaOverviewComponent implements OnInit {
  displayedColumns: string[] = ['description', 'actions'];
  dataSource: MatTableDataSource<Area>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Select(AreaState.areas) areas: Observable<Area[]>;


  constructor(private store: Store,
              public dialog: MatDialog,
              private logger: NGXLogger,
              private deleteAreaMutation: DeleteAreaMutation) {
  }

  ngOnInit() {
    this.store.dispatch(new FetchAreasAction());
    this.areas.subscribe(areas => {
      this.dataSource = new MatTableDataSource(areas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  getFloat(number: string) {
    return parseFloat(number);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRelay(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Dieses Relay wirklich löschen?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAreaMutation.mutate({id}).subscribe(({data}) => {
          this.logger.log('got Relay delete data', data);
          this.store
            .dispatch(new FetchAreasAction());
        }, (error) => {
          error.graphQLErrors.map(({message}, i) => (
            this.logger.error(message, i)
          ));
        });
      }
    });
  }
}

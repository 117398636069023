import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PaymentsModule} from '../public/payments/payments.module';
import {SharedModule} from '../shared/shared.module';
import {DashComponent} from './components/dash/dash.component';
import {HomeComponent} from './components/home/home.component';
import {MainComponent} from './components/main/main.component';
import {SideNavMenuComponent} from './components/side-nav-menu/side-nav-menu.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    SharedModule,
    PaymentsModule,
  ],
  declarations: [
    DashComponent,
    MainComponent,
    SideNavMenuComponent,
    HomeComponent],
})
export class RootModule {
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {UserDetailComponent} from './components/user-detail/user-detail.component';
import {OverviewComponent} from './components/overview/overview.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [],
  declarations: [OverviewComponent, UserDetailComponent],
})
export class UserModule {
}

import { Routes } from '@angular/router';
import { BarcodesOverviewComponent } from './components/barcodes-overview/barcodes-overview.component';
import { BarcodeItemsComponent } from './components/barcode-items/barcode-items.component';

export const BARCODE_ROUTES: Routes = [

  { path: 'admin/barcodes', component: BarcodesOverviewComponent },
  { path: 'admin/barcode_items', component: BarcodeItemsComponent },

];

import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Team} from '../../../../../../../common/interfaces/prisma.binding';

export interface Response {
  allTeams: Team[];
}

@Injectable({
  providedIn: 'root',
})
export class AllTeamsQuery extends Query<Response> {
  document = gql`
      query allTeams {
          teams {
              id
              container {
                  id
                  internalNumber
              }
              name
              users {
                  id
                  firstName
                  lastName
                  username
              }
              meterReadings {
                  id
                  entryDate
                  meterReading
                  meter {
                      id
                  }
                  kwhPriceOnReading
              }
          }
      }
  `;
}

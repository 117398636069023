import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateElectricMeterMutation extends Mutation {
  document = gql`
      mutation createElectricMeter($address: String!,$brandId:ID!,$type: MeterType!,$comment:String) {
          createElectricMeter(data: {
              address:$address
              type:$type,
              comment:$comment
              brand: {
                  connect: {id: $brandId}
              }
          }) {id}
      }
  `;
}

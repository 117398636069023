import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import {
  GPIOController,
  GPIODevice,
  Relay,
  Team,
  User,
} from '../../../../../../../../common/interfaces/prisma.binding';
import { FetchGpioDevicesAction } from '../../../gpio-devices/actions/fetch-gpio-devices.action';
import { GpioDevicesState } from '../../../gpio-devices/gpio-devices.state';
import { FetchTeamsAction } from '../../../team/actions/fetch-teams.action';
import { TeamState } from '../../../team/team.state';
import { FetchUserAction } from '../../../user/actions/fetch-user.action';
import { UserState } from '../../../user/user.state';
import { FetchRelaysAction } from '../../actions/fetch-relays.action';
import { CreateRelayMutation } from '../../mutations/create-relay-mutation';
import { UpdateRelayMutation } from '../../mutations/update-relay-mutation';
import { RelaysState } from '../../relays.state';
import { RelayService } from '../../relay.service';

@Component({
  selector: 'tt-relays-detail',
  templateUrl: './relays-detail.component.html',
  styleUrls: ['./relays-detail.component.scss'],
})
export class RelaysDetailComponent implements OnInit {
  @Select(TeamState.teams) teams$: Observable<Team[]>;
  @Select(UserState.users) users$: Observable<User[]>;
  @Select(GpioDevicesState.gpioDevices) gpioDevices$: Observable<GPIODevice[]>;
  @Select(RelaysState.relays) relays$: Observable<Relay[]>;
  editMode = false;

  relays: Relay[];
  currentRelay: Relay;
  autoReleaseValue: boolean;
  users;
  team;
  gpioDevice;
  initiallySelectedTeam$ = new Subject<[any]>();
  initiallySelectedUsers$ = new Subject<[any]>();
  initiallySelectedGpioDevice$ = new Subject<[any]>();
  gpioControllers: GPIOController[] = ['INTERNAL', 'MCP_23017'];

  relayForm: FormGroup;
  disconnectUsers = [];

  private _id: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private createRelayMutation: CreateRelayMutation,
    private updateRelayMutation: UpdateRelayMutation,
    private relaysService: RelayService,
  ) {}

  ngOnInit() {
    this.relayForm = this.formBuilder.group({
      description: [''],
      pin: ['', Validators.required],
      team: [''],
      users: [''],
      connectedGPIODevice: ['', Validators.required],
      gpioController: [''],
      autoRelease: [false],
      autoReleaseTimeInMs: [1000],
      autoToggle: [false],
    });
    this.store
      .dispatch([
        new FetchRelaysAction(),
        new FetchGpioDevicesAction(),
        new FetchUserAction(),
        new FetchTeamsAction(),
      ])
      .subscribe(() => {
        this.relays = this.store.selectSnapshot(RelaysState.relays);

        this.route.params.subscribe(params => {
          if (params['id'] !== undefined) {
            this.editMode = true;
            this._id = params['id'];
            this._populateForm(this._id);
          }
        });
      });
  }

  createRelay(createNewAfter = false) {
    this.createRelayMutation
      .mutate({
        description: this.relayForm.value['description'].toString(),
        pin: parseInt(this.relayForm.value['pin'].toString(), 10),
        connectedGPIODevice: this.gpioDevice,
        users: this.users,
        team: this.team,
        gpioController: this.relayForm.value['gpioController'].toString(),
        autoRelease: this.autoReleaseValue,
        autoReleaseTimeInMs: parseInt(
          this.relayForm.value['autoReleaseTimeInMs'].toString(),
          10,
        ),
        autoToggle: this.relayForm.value.autoToggle,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create relay', data);
          // refresh store
          this.store.dispatch(new FetchRelaysAction());
          if (createNewAfter) {
            this.router.navigate([`/admin/relays/add`]);
          } else {
            this.router.navigate([
              `/admin/relays/edit/${data['createRelay'].id}`,
            ]);
          }
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  onAutoReleaseChange(event) {
    this.autoReleaseValue = event.checked;
  }

  updateRelay() {
    // constructiong the disconnects
    if (this.users.disconnect === undefined) {
      this.currentRelay.users.forEach(user => {
        if (!this.users.connect.find(userId => userId.id === user.id)) {
          this.disconnectUsers.push({ id: user.id });
        }
      });
      this.users = { ...this.users, disconnect: this.disconnectUsers };
    }

    this.updateRelayMutation
      .mutate({
        id: this._id,
        description: this.relayForm.value['description'].toString(),
        pin: parseInt(this.relayForm.value['pin'].toString(), 10),
        users: this.users,
        team: this.team,
        connectedGPIODevice: this.gpioDevice,
        gpioController: this.relayForm.value['gpioController'].toString(),
        autoRelease: this.autoReleaseValue,
        autoReleaseTimeInMs: parseInt(
          this.relayForm.value['autoReleaseTimeInMs'].toString(),
          10,
        ),
        autoToggle: this.relayForm.value.autoToggle,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got update relays', data);
          // refresh store
          this.store.dispatch(new FetchRelaysAction());
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            // proper error handling
            this.logger.error(message, i),
          );
        },
      );
  }

  onTeamSelectionChange(event) {
    if (event.length > 0) {
      this.team = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.team = { disconnect: true };
    }
  }

  onGpioDeviceSelectionChange(event) {
    this.relayForm.patchValue({
      connectedGPIODevice: event,
    });
    if (event.length > 0) {
      this.gpioDevice = {
        connect: {
          id: event[0].id,
        },
      };
    } else {
      // @ts-ignore
      this.gpioDevice = { disconnect: true };
    }
  }

  onUserSelectionChange(event) {
    if (event.length > 0) {
      this.users = {
        connect: event.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    } else {
      // @ts-ignore
      this.users = {
        disconnect: this.currentRelay.users.map(singleEvent => {
          return {
            id: singleEvent.id,
          };
        }),
      };
    }
  }

  testTriggerRelais() {
    this.relaysService.testTriggerRelais(this._id);
  }

  private _populateForm(id: string) {
    this.relays$.subscribe(relays => {
      // @ts-ignore
      this.currentRelay = relays.find(relay => relay.id === id);
      if (this.currentRelay) {
        if (this.currentRelay.users) {
          this.users = {
            connect: this.currentRelay.users.map(singleUser => {
              return {
                id: singleUser.id,
              };
            }),
          };
          // @ts-ignore
          this.initiallySelectedUsers$.next(this.currentRelay.users);
        }
        if (this.currentRelay.team) {
          this.team = {
            connect: {
              id: this.currentRelay.team.id,
            },
          };

          // @ts-ignore
          this.initiallySelectedTeam$.next([this.currentRelay.team]);
        }
        if (this.currentRelay.connectedGPIODevice) {
          this.gpioDevice = {
            connect: {
              id: this.currentRelay.connectedGPIODevice.id,
            },
          };

          // @ts-ignore
          this.initiallySelectedGpioDevice$.next([
            this.currentRelay.connectedGPIODevice,
          ]);
        }
        this.autoReleaseValue = this.currentRelay.autoRelease;
        this.relayForm.patchValue({
          description: this.currentRelay.description,
          pin: this.currentRelay.pin,
          users: this.currentRelay.users,
          team: this.currentRelay.team,
          connectedGPIODevice: this.currentRelay.connectedGPIODevice,
          gpioController: this.currentRelay.gpioController,
          autoRelease: this.currentRelay.autoRelease,
          autoReleaseTimeInMs: this.currentRelay.autoReleaseTimeInMs,
          autoToggle: this.currentRelay.autoToggle,
        });
      }
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractSubscription} from '../../../../../../../../common/interfaces/prisma.binding';

@Component({
  selector: 'tt-abstract-subscriptions',
  templateUrl: './abstract-subscriptions.component.html',
  styleUrls: ['./abstract-subscriptions.component.scss'],
})
export class AbstractSubscriptionsComponent {
  @Input()
  abstractSubscriptions: Observable<AbstractSubscription[]>;
  @Input()
  enabled = true;
  @Input()
  userSubscriptions = [];
  @Output()
  chancelPlan = new EventEmitter<string>();
  @Output()
  selectedPlan = new EventEmitter<AbstractSubscription>();


  chancelSubscription(planId: string) {
    this.chancelPlan.emit(planId);
  }

  selectPlan(plan: AbstractSubscription) {
    this.selectedPlan.emit(plan);
  }

  checkIfAlreadySubscribed(planID: string): boolean {
    if (this.userSubscriptions.length > 0) {
      return this.userSubscriptions[0].includes(planID);
    }
    return false;
  }

}

import {Routes} from '@angular/router';
import {ContainerDetailComponent} from './components/container-detail/container-detail.component';
import {ContainerOverviewComponent} from './components/container-overview/container-overview.component';


export const CONTAINER_ROUTES: Routes = [

  {path: 'admin/container', component: ContainerOverviewComponent},
  { path: 'admin/container/add', component: ContainerDetailComponent },
  { path: 'admin/container/edit/:id', component: ContainerDetailComponent }
];

import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { StripePlan } from '../../../../../../../common/interfaces/stripe.bindings';

export interface Response {
  stripePlans: StripePlan[];
}

@Injectable({
  providedIn: 'root',
})
export class StripePlansQuery extends Query<Response> {
  document = gql`
    query stripePlans {
      stripePlans {
        amount
        currency
        planID
        product {
          productId
          metadata
          name
          updated
        }
      }
    }
  `;
}

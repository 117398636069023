import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateStripePaymentIntentMutation extends Mutation {
  document = gql`
    mutation createStripePaymentIntent($amount: Int!,$confirm: Boolean!,$currency: String!,$source: String!,$description: String!) {
      createStripePaymentIntent(data: {amount: $amount, currency: $currency, confirm: $confirm, source:$source, description: $description} ){
        amount
        client_secret
        status
      }
    }
  `;
}

import {Component, Inject} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ISnackbarData} from '../../interfaces/snackbar-data.interface';
import {UiState} from '../../state/ui.state';
import {copyStringToClipboard} from '../../utils/copy-string-to-clipboard';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'tt-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],

})
export class SnackbarComponent {
  @Select(UiState.getSnackbarQueue) snackbarQueue$: Observable<ISnackbarData[]>;
  snackbarSize: number;
  snackbarQue: ISnackbarData[];

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
  ) {
    this.snackbarQueue$.subscribe(snackbarQueue => {
      this.snackbarSize = snackbarQueue.length;
      this.snackbarQue = snackbarQueue;
    });
  }

  showNextMessage() {
    if (this.getPositionInQueue() < this.snackbarQue.length - 1) {
      this.data = this.snackbarQue[this.getPositionInQueue() + 1];
    }
  }

  showPreviousMessage() {
    if (this.getPositionInQueue() > 0) {
      this.data = this.snackbarQue[this.getPositionInQueue() - 1];
    }
  }

  copyMessageToClipboard() {
    copyStringToClipboard(this.data.message);
  }

  getMailText(): string {
    return `mailto:bugs@tooltime.berlin?Subject=[Tool Time Frontend] Bug&body=${this.data.message}`;
  }

  getPositionInQueue(): number {
    return this.snackbarQue.findIndex(elements => elements.id === this.data.id);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {User} from '../../../../../../../common/interfaces/prisma.binding';
import {UpdateUserSubscription} from '../../../admin/user/subscriptions/update-user.subscription';
import {UpdateUserCredit} from '../../../shared/actions/update-user-credit.action';
import {UiService} from '../../../shared/services/ui.service';
import {AuthState} from '../../../shared/state/auth.state';

@Component({
  selector: 'tt-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {

  constructor(private ui: UiService,
              private updateUserSubscription: UpdateUserSubscription,
              private store: Store) {
  }

  ngOnInit() {
    const currentUser = this.store.selectSnapshot(AuthState.userDetails) as User;

    this.updateUserSubscription.subscribe({id: currentUser.id})
      .subscribe(updatedUser => this.store.dispatch(new UpdateUserCredit(updatedUser.data.user.node.credit)));

  }

  ngOnDestroy(): void {
  }
}
